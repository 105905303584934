import { useCallback, useRef } from 'react'
import { makeStyles, Typography, Paper, Box } from '@material-ui/core'
import Webcam from 'react-webcam'
import { updatePictureDocument } from '../../../../store/reducers/order'
import { useDispatch, useSelector } from 'react-redux'
import pictureMaskDocumentImage from '../../../../assets/image/pictureMaskDoc.png'
import pictureShutter from '../../../../assets/image/shutter.png'
import pictureShutterDiscard from '../../../../assets/image/shutterDiscard.png'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
	paper: {
		paddingTop: '25px',
		paddingBottom: '25px',
		paddingLeft: '32px',
		paddingRight: '32px',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
	},
	container: {
		position: 'relative',
		width: '600px',
		height: '480px',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
			height: 'auto',
		},
	},
	media: {
		maxWidth: '100%',
		objectFit: 'cover',
		display: 'block',
		width: '600px',
		height: '480px',
		zIndex: 1,
	},
	overlay: {
		position: 'absolute',
		bottom: '0px',
		width: '100%',
		height: '100%',
		opacity: '90%',
		zIndex: 2,
	},
	shutter: {
		position: 'absolute',
		bottom: '10px',
		left: 'calc(50% - 25px)',
		width: '50px',
		height: '50px',
		opacity: '90%',
		zIndex: 3,
		cursor: 'pointer',
	},
	didsTitle: {
		width: '0',
		minWidth: '100%',
	},
	didsDescription: {
		width: '0',
		minWidth: '100%',
		lineHeight: '19px',
	},
}))

const PictureDocument = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const StoreOrder = useSelector(state => state.Order)

	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: 'user',
	}
	const webcamRef = useRef(null)

	const capture = useCallback(async () => {
		const imageSrc = webcamRef.current.getScreenshot()
		dispatch(updatePictureDocument(imageSrc))
	}, [webcamRef, dispatch])

	const discard = useCallback(async () => {
		dispatch(updatePictureDocument(undefined))
	}, [dispatch])

	return (
		<Paper className={classes.paper}>
			<Box>
				<Typography
					className={classes.didsTitle}
					align="left"
					variant="h4"
					gutterBottom
				>
					Posicione seu documento em frente a camera
				</Typography>
				<Typography
					className={classes.didsDescription}
					variant="subtitle1"
					gutterBottom
				>
					Enquadre o seu documento na marcação da câmera e pressione o botão
					Tirar foto!
				</Typography>
				<div className={classes.container}>
					{!StoreOrder.pictureDocument ? (
						<>
							<Webcam
								className={classes.media}
								mirrored={true}
								audio={false}
								height={480}
								ref={webcamRef}
								screenshotFormat="image/jpeg"
								width={600}
								videoConstraints={videoConstraints}
							/>
							{!isMobile && (
								<img
									src={pictureMaskDocumentImage}
									className={classes.overlay}
									alt=""
								/>
							)}
							<img
								src={pictureShutter}
								onClick={capture}
								className={classes.shutter}
								alt=""
							/>
						</>
					) : (
						<>
							<img
								src={StoreOrder.pictureDocument.image}
								className={classes.media}
								alt=""
							/>
							<img
								src={pictureShutterDiscard}
								onClick={discard}
								className={classes.shutter}
								alt=""
							/>
						</>
					)}
				</div>
			</Box>
		</Paper>
	)
}

export default PictureDocument
