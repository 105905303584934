import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
	makeStyles,
	Typography,
	Paper,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	IconButton,
	Checkbox,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Box,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
	updateNumbersNew,
	updateNumbersLNP,
	loadNumbers,
} from '../../../../store/reducers/order'
import {
	Refresh as RefreshIcon,
	ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { isMobile } from 'react-device-detect'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
	paper: {
		height: '540px',
		width: '820px',
		padding: '32px',
		borderRadius: '10px',
		border: 'none',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
		},
	},
	cardHeader: {
		fontSize: '18px',
		lineHeight: '21px',
		color: '#0F94DA',
	},
	numbersTitle: {
		fontWeight: 400,
		fontSize: '34px',
		lineHeight: '48px',
		paddingBottom: '24px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '32px',
			fontSize: '28px',
		},
	},
	numbersGrid: {
		marginTop: '25px',
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridAutoRows: '1fr',
		columnGap: '16px',
		rowGap: '10px',
		height: '100%',
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: 'repeat(1, 1fr)',
		},
	},
	numbersGridItem: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	gridRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '85px',
		},
	},
	accordionCard: {
		margin: '5px 0px',
		//backgroundColor: 'blue'
		border: '1px solid #D3D3D3',
		borderRadius: '8px',
		boxShadow: 'none',
	},
	accordionExpanded: {
		margin: '8px 0px !important',
		//backgroundColor: 'red',
		flexGrow: '1',
		height: '300px',
		border: '1px solid #D3D3D3',
		borderRadius: '8px',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
		},
	},
	accordionTitle: {
		'& div': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	accordionBody: {
		flexDirection: 'column',
	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
	newNumbersTitle: {
		fontWeight: '500',
		fontSize: '18px',
		lineHeight: '24px',
		color: '#0F94DA',
	},
	portabilityTitle: {
		fontWeight: '500',
		fontSize: '18px',
		lineHeight: '24px',
		color: '#626262',
	},
	numbersList: {
		height: '240px',
		overflow: 'auto',
		width: '100%',
		padding: 0,
	},
	numbersListItem: {},
	numbersListChecked: {
		border: '1px solid #3f51b5',
		borderRadius: '5px',
		marginBottom: '5px',
	},
	numbersListUnchecked: {
		border: '1px solid #d5d5d5',
		borderRadius: '5px',
		marginBottom: '5px',
	},
	numbersListItemIcon: {
		'& span': { padding: '0px 5px 0px 5px' },
	},
	numbersListCheckbox: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	choosen: {
		height: '100%',
	},
	allowedNumbers: {
		color: '#4D4D4D',
	},
	allowedNumbersWarning: {
		color: '#921010',
		fontWeight: '500',
	},
	choosenTable: {
		border: '1px solid #d3d3d3',
		borderRadius: '8px',
		paddingLeft: '10px',
		paddingRight: '10px',
		overflow: 'auto',
		height: 'calc(100% - 70px)',
	},
	choosenTableCell: {
		verticalAlign: 'top',
	},
	portabilityDescription: {
		paddingLeft: '16px',
	},
	portabilityField: {
		'& textarea': {
			height: '164px',
			[theme.breakpoints.down('sm')]: {
				height: 'auto',
			},
		},
	},
	tooltip: {
		'& div': {
			backgroundColor: '#FD3E3E',
		},
	},
	selectedNumbersTitle: {
		fontSize: '16px',
		marginBottom: '5px',
		color: '#212121',
	},
	selectedNumbersCounter: {
		fontSize: '12px',
		marginTop: '1px',
		color: '#626262',
	},
	selectedNumbersWarning: {
		fontSize: '12px',
		marginTop: '1px',
		color: '#921010',
		fontWeight: '500',
	},
}))

const Numbers = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	const Order = useSelector(({ Order }) => Order)
	const numbers = useSelector(state => state.Number.list)
	const [qtySelectedNumbers, setQtySelectedNumbers] = useState(0)
	const [value, setValue] = useState('')
	const [expanded, setExpanded] = useState('panel1')
	const trial = Order.plan?.price === 0

	const onCheck = useCallback(
		(id, data) => {
			if (!data?._id) return

			const newChecked = new Map(Order.numbersNew)
			if (newChecked.get(id)) {
				newChecked.delete(id)
			} else {
				newChecked.set(id, data)
			}
			dispatch(updateNumbersNew(newChecked))
		},
		[Order.numbersNew, dispatch]
	)

	const handleChange = event => {
		let newValue = new Map()
		let validNumbers = []
		let invalidNumbers = []
		let allNumbers = event.target.value.split('\n')

		allNumbers.forEach(element => {
			element = element.replace(/\D/g, '')
			if (
				isValidPhoneNumber(element, 'BR') &&
				element !== '' &&
				element.length === 10
			) {
				validNumbers.push(element)
			} else {
				invalidNumbers.push(element)
			}
		})
		if (
			event.target.value.charAt(event.target.value.length - 1) === '\n' &&
			invalidNumbers.length > 0 &&
			invalidNumbers[0] !== ''
		) {
			setValue(event.target.value.replace(/\n$/, ''))
		} else {
			validNumbers.map(validNumbers =>
				newValue.set(validNumbers, {
					id: validNumbers,
					number: validNumbers,
					qty: 1,
					city: '',
				})
			)
			setValue(event.target.value)
			dispatch(updateNumbersLNP(newValue))
		}
	}

	const numberRange = (number, qty) => {
		return parseInt(number.substring(number.length - 4)) + parseInt(qty) - 1
	}

	let merged = useMemo(
		() => new Map([...Order.numbersNew, ...Order.numbersLNP]),
		[Order.numbersLNP, Order.numbersNew]
	)

	const handleAccordion = panel => (event, isExpanded) => {
		if (trial) return
		setExpanded(isExpanded && panel === 'panel1' ? panel : 'panel2')
	}

	useEffect(() => {
		if (numbers === null) {
			history.push('/order/coming')
		} else if (numbers.length === 0) {
			dispatch(loadNumbers())
		}
	}, [numbers, history, dispatch])

	useEffect(() => {
		const qtySum = (acc, num) => {
			return Number(acc) + Number(num)
		}
		const numCount =
			[...merged].length > 0
				? [...merged]
						.map(([key, value]) => {
							return value.qty
						})
						.reduce(qtySum)
				: 0
		setQtySelectedNumbers(numCount)
		// TODO: evaluate deps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merged])

	return _.isEmpty(numbers) && !isMobile ? (
		<></>
	) : (
		<Paper className={classes.paper}>
			<Box className={classes.gridRoot}>
				<Typography variant="h4">
					Escolha {Order.plan.dids > 1 ? 'os números' : 'o número'} da sua nova
					linha
				</Typography>
				<Grid className={classes.numbersGrid}>
					<Grid className={classes.numbersGridItem}>
						<Accordion
							className={
								expanded === 'panel1'
									? classes.accordionExpanded
									: classes.accordionCard
							}
							expanded={expanded === 'panel1'}
							onChange={handleAccordion('panel1')}
						>
							<AccordionSummary
								className={classes.accordionTitle}
								expandIcon={trial ? undefined : <ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header"
							>
								<Typography className={classes.newNumbersTitle}>
									Números Novos
								</Typography>
								<FormControlLabel
									control={
										<IconButton
											onClick={() => dispatch(loadNumbers())}
											size="small"
										>
											<RefreshIcon />
										</IconButton>
									}
									aria-label="Acknowledge"
									onClick={event => event.stopPropagation()}
									onFocus={event => event.stopPropagation()}
								/>
							</AccordionSummary>
							<AccordionDetails>
								<List
									dense
									component="div"
									role="list"
									className={classes.numbersList}
								>
									{numbers &&
										numbers.map(({ _id, number, qty }, index) => {
											const labelId = `transfer-list-item-${_id}-label`
											let isChecked = !!Order.numbersNew.get(_id)
											return (
												<ListItem
													key={_id}
													role="listitem"
													className={
														isChecked
															? classes.numbersListChecked
															: classes.numbersListUnchecked
													}
													disablegutters="true"
												>
													<ListItemIcon className={classes.numbersListItemIcon}>
														<Checkbox
															className={classes.numbersListCheckbox}
															checked={isChecked}
															onChange={() =>
																onCheck(_id, {
																	_id,
																	number,
																	qty,
																})
															}
															tabIndex={-1}
															disableRipple
															disablegutters="true"
															color="primary"
															inputProps={{ 'aria-labelledby': labelId }}
														/>
													</ListItemIcon>
													{qty > 1 ? (
														<ListItemText
															id={labelId}
															primary={`${parsePhoneNumber(
																number,
																'BR'
															).formatNational()} até ${numberRange(
																number,
																qty
															)} (${qty} números)`}
														/>
													) : (
														<ListItemText
															id={labelId}
															primary={`${parsePhoneNumber(
																number,
																'BR'
															).formatNational()}`}
														/>
													)}
												</ListItem>
											)
										})}
								</List>
							</AccordionDetails>
						</Accordion>
						<Accordion
							disabled={trial}
							className={
								expanded === 'panel2'
									? classes.accordionExpanded
									: classes.accordionCard
							}
							expanded={expanded === 'panel2'}
							onChange={handleAccordion('panel2')}
						>
							<AccordionSummary
								className={classes.accordionTitle}
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header"
							>
								<Typography className={classes.portabilityTitle}>
									Portabilidade
								</Typography>
							</AccordionSummary>
							<Typography className={classes.portabilityDescription}>
								Informe os números que deseja portar:
							</Typography>
							<AccordionDetails className={classes.accordionBody}>
								<TextField
									id="outlined-multiline-static"
									multiline
									rows={4}
									className={classes.portabilityField}
									onKeyDown={event => {
										if (event.keyCode === 8 || event.keyCode === 46) {
											let newV = value.substring(0, value.length - 1)
											let newValue = new Map()
											let input = newV.split('\n').filter(element => {
												if (
													isValidPhoneNumber(element, 'BR') &&
													element.length === 10
												) {
													return true
												}
												return false
											})
											input.map(input =>
												newValue.set(input, {
													id: input,
													number: input,
													qty: 1,
													city: '',
													lnp: true,
												})
											)
											setValue(newV)
											dispatch(updateNumbersLNP(newValue))
										}
									}}
									onChange={handleChange}
									value={value}
									variant="outlined"
								/>
								<Typography variant="caption" display="block" gutterBottom>
									Adicione um número por linha. Ex: 1133332222
								</Typography>
							</AccordionDetails>
						</Accordion>
					</Grid>
					<Grid className={classes.numbersGridChoosedItem}>
						<Box className={classes.choosen}>
							<Typography className={classes.selectedNumbersTitle} gutterBottom>
								Seu plano permite
								<span
									className={
										qtySelectedNumbers > Order.plan.dids
											? classes.allowedNumbersWarning
											: classes.allowedNumbers
									}
								>
									&nbsp;
									{Order.plan.dids} número{Order.plan.dids > 1 && <>s</>}
								</span>
							</Typography>
							<Box className={classes.choosenTable}>
								<Table aria-label="simple table">
									<TableBody>
										{[...merged].map(([key, value], index) => {
											return (
												<TableRow key={index}>
													<TableCell
														component="th"
														scope="row"
														onClick={() =>
															onCheck(value._id, {
																_id: value._id,
																number: value.number,
																qty: value.qty,
															})
														}
														className={classes.choosenTableCell}
													>
														{parsePhoneNumber(
															value.number,
															'BR'
														).formatNational()}{' '}
														{value.qty > 1
															? `até ${numberRange(value.number, value.qty)} (${
																	value.qty
															  } números)`
															: undefined}{' '}
														{!value._id && `(Port.)`}
													</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							</Box>
							<Typography className={classes.selectedNumbersCounter}>
								{`Números Selecionados ${qtySelectedNumbers}/${Order.plan.dids}`}
							</Typography>
							<Typography className={classes.selectedNumbersWarning}>
								{qtySelectedNumbers > Order.plan.dids && (
									<>Você selecionou mais números do que o seu plano permite.</>
								)}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

export default Numbers
