import { useEffect } from 'react'
import {
	makeStyles,
	Typography,
	Paper,
	Box,
	AppBar,
	Toolbar,
	Grid,
	Container,
} from '@material-ui/core'
import { ReactComponent as SuccessIcon } from '../../../assets/image/success.svg'
import { useSelector } from 'react-redux'
import gtag from 'ga-gtag'
import bgImage from '../../../assets/image/bg.jpg'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		position: 'relative',
		backgroundColor: '#F4F4F4',
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	appbar: {
		backgroundColor: '#fff',
	},
	paper: {
		paddingTop: '25px',
		paddingBottom: '25px',
		paddingLeft: '32px',
		paddingRight: '32px',
		position: 'absolute',
		top: '50%',
		right: '50%',
		transform: 'translate(50%,-50%)',
	},
	toolbar: {
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 100,
		paddingLeft: 100,
	},
	media: {
		maxWidth: '100%',
	},
	successTitle: {
		width: '100%',
		minWidth: '100%',
		fontSize: '34px',
		textAlign: 'center',
		color: '#626262',
	},
	successRequestTitle: {
		fontSize: '14px',
		fontWeight: '700',
		color: '#626262',
	},
	successIcon: { margin: '20px' },
	successRequestNumber: {
		fontSize: '18px',
		fontWeight: '400',
		color: '#626262',
	},
	successRequestDescription: {
		fontSize: '16px',
		fontWeight: '400',
		color: '#626262',
		marginTop: '15px',
	},
	footer: {
		paddingLeft: '73px',
		paddingBottom: '28px',
		marginTop: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	footerText: {
		color: '#aaa',
		marginRight: '5px',
	},
}))

const Success = () => {
	const classes = useStyles()
	const order = useSelector(state => state.Order)

	useEffect(() => {
		gtag('event', 'page_view', {
			send_to: 'G-Z7ZHCKVSNM',
			page_title: 'Pedido concluído',
			page_path: '/order/success',
		})
	}, [])

	return (
		<Grid item xs={12} className={classes.root}>
			<AppBar position="static" color="default" className={classes.appbar}>
				<Toolbar className={classes.toolbar}>
					<img
						src="https://www.faleuniq.com.br/images/logo-01.png"
						alt="logo"
						style={{
							height: 50,
						}}
					/>
				</Toolbar>
			</AppBar>
			<main>
				<Container>
					<Paper className={classes.paper}>
						<Box>
							<Container>
								<Grid align="center">
									<Typography
										className={classes.successTitle}
										align="left"
										variant="h4"
										gutterBottom
									>
										Pedido concluído com sucesso
									</Typography>
									<SuccessIcon className={classes.successIcon} />
									<div>
										<Typography className={classes.successRequestTitle}>
											Número do Pedido
										</Typography>
										<Typography className={classes.successRequestNumber}>
											#{order.completedOrderId}
										</Typography>
									</div>
								</Grid>
								<Typography className={classes.successRequestDescription}>
									Em breve você receberá um e-mail com o resumo do pedido, e
									assim que o seu serviço estiver habilitado você receberá um
									novo e-mail com as informações de acesso ao ambiente.
								</Typography>
							</Container>
						</Box>
					</Paper>
				</Container>
			</main>
		</Grid>
	)
}

export default Success
