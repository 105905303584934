export const Types = {
	LIST: 'reducer/provider/@LIST',
}

const InitialState = {
	list: [],
}

const Provider = (state = InitialState, action) => {
	switch (action.type) {
		case Types.LIST:
			return { ...state, list: action.payload }
		default:
			return state
	}
}

export default Provider
