const Types = {
	API: 'reducer/payment/@API',
	CRYPTOGRAPHY: 'reducer/payment/@CRYPTOGRAPHY',
}

export const setAPI = api => {
	return {
		type: Types.API,
		payload: api,
	}
}

export const setCryptography = data => {
	return {
		type: Types.CRYPTOGRAPHY,
		payload: data,
	}
}

const InitialState = {
	api: '',
	cryptography: '',
}

const Payment = (state = InitialState, action) => {
	switch (action.type) {
		case Types.API:
			return { ...state, api: action.payload }
		case Types.CRYPTOGRAPHY:
			return { ...state, cryptography: action.payload }
		default:
			return state
	}
}

export default Payment
