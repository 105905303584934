import React, { useCallback, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Typography, Paper, makeStyles, Grid, Box } from '@material-ui/core'
import { setTech } from '../../../../store/reducers/order'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from '@unform/web'
import { isValidEmail } from '@brazilian-utils/brazilian-utils'
import Input from '../../../../components/input'

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		overflow: 'hidden',
	},
	paper: {
		padding: 32,
		height: '400px',
		width: '820px',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
		},
	},
	media: {
		height: 100,
		width: '100%',
	},
	gridContainer: {
		height: '100%',
		paddingBottom: 20,
	},
	card: {
		margin: 10,
	},
	gridRoot: {
		marginTop: '25px',
		padding: '10px 12px',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	icon: {
		marginBottom: 0,
		marginRight: 7,
	},
	appBar: {
		backgroundColor: '#fff',
	},
	form: {
		paddingRight: '20px',
		overflow: 'hidden',
		'& input': {
			fontSize: '14px',
			borderBottom: '1.3px solid #626262',
		},
	},
	labelIcon: {
		minHeight: '40px',
	},
	formSubTitle: {
		width: '100%',
		fontSize: '16px',
		color: '#626262',
		fontWeight: 500,
		marginBottom: '10px',
		paddingLeft: '12px',
	},
	tabs: {
		backgroundColor: 'transparent',
	},
}))

const Tech = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const formRefTech = useRef()
	const [validEmail, setValidEmail] = useState(true)
	const { Order: StoreOrder } = useSelector(({ Order }) => {
		return { Order }
	})

	const saveTech = useCallback(
		data => {
			dispatch(setTech({ ...StoreOrder.tech, ...data }))
		},
		[dispatch, StoreOrder.tech]
	)

	const handleEmailChange = event => {
		const email = event.target.value
		if (!email || email === '') {
			setValidEmail(true)
		} else {
			setValidEmail(isValidEmail(email))
		}
	}

	const handleFormSubmit = async data => {
		try {
			handleFormSubmit?.current?.setErrors({})
			const schema = Yup.object().shape({
				companyDisplay: Yup.string().required(),
				techName: Yup.string().required(),
				techEmail: Yup.string().required().email(),
			})
			await schema.validate(data, {
				abortEarly: false,
			})
			saveTech(data)
		} catch (err) {
			const validationErrors = {}
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach(error => {
					validationErrors[error.path] = error.message
				})
				// formRefCompany?.current?.setErrors(validationErrors);
			}
		}
	}

	return (
		<>
			<Paper className={classes.paper}>
				<Typography variant="h4" gutterBottom>
					Configuração da empresa
				</Typography>
				<Box className={classes.gridRoot}>
					<Form
						initialData={{ ...StoreOrder.tech }}
						ref={formRefTech}
						onSubmit={handleFormSubmit}
						index={0}
						className={classes.form}
					>
						<Grid container spacing={3}>
							<h4 className={classes.formSubTitle}>Dados da empresa</h4>
							<Grid item xs={12}>
								<Input
									required
									name="companyDisplay"
									placeholder="Nome"
									fullWidth
									helperText="Nome fantasia que aparecerá na interface dos usuários"
									onChange={() => formRefTech?.current?.submitForm()}
								/>
							</Grid>

							<h4
								className={classes.formSubTitle}
								style={{ marginBottom: '0' }}
							>
								Conta de administração
							</h4>
							<span
								className="MuiFormHelperText-root MuiFormHelperText-filled Mui-required"
								style={{
									marginTop: '0',
									marginBottom: '3px',
									marginLeft: '12px',
								}}
							>
								Nome e e-mail do administrador que irá receber os dados de
								acesso ao painel de controle
							</span>

							<Grid item xs={12} sm={6}>
								<Input
									required
									name="techName"
									placeholder="Nome"
									fullWidth
									onChange={() => formRefTech?.current?.submitForm()}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Input
									required
									name="techEmail"
									placeholder="E-mail"
									fullWidth
									error={validEmail ? false : 'Informe um e-mail válido'}
									onChange={() => formRefTech?.current?.submitForm()}
									onBlur={handleEmailChange}
								/>
							</Grid>
						</Grid>
					</Form>
				</Box>
			</Paper>
		</>
	)
}

export default Tech
