import {
	makeStyles,
	Paper,
	Grid,
	Typography,
	FormControlLabel,
	Checkbox,
	Box,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import {
	updateContract1,
	updateContract2,
	updateContract3,
} from '../../../../store/reducers/order'

const useStyles = makeStyles(theme => ({
	paper: {
		paddingTop: '30px',
		paddingBottom: '30px',
		paddingLeft: '32px',
		paddingRight: '32px',
		borderRadius: '10px',
		border: 'none',
		width: '820px',
		boxShadow: 'none',
		height: '540px',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
			padding: 16,
		},
	},
	gridContainer: {
		overflow: 'auto',
		height: '100%',
		padding: '10px',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
		},
	},
	contract: {
		fontSize: '11px',
	},
	notes: {
		fontSize: '9px',
		marginTop: '10px',
		textAlign: 'justify',
	},
	gridRoot: {
		height: 'calc(100% - 30px)',
		marginTop: '10px',
		paddingBottom: '32px',
	},
	formControlLabel: {
		alignItems: 'start',
		marginTop: '15px',
	},
	formControlLabelCheck: {
		paddingTop: 0,
	},
	ipAnnouncement: {
		marginTop: '10px',
		wordBreak: 'break-word',
	},
}))

const TermsOfUse = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const StoreOrder = useSelector(state => state.Order)
	const contract1 = StoreOrder.contract1
	const contract2 = StoreOrder.contract2
	const contract3 = StoreOrder.contract3
	const trial = StoreOrder.plan?.price === 0

	return (
		<Paper className={classes.paper}>
			<Typography align="left" variant="h4">
				Contratos de Serviço
			</Typography>
			<Box className={classes.gridRoot}>
				<Grid container className={classes.gridContainer}>
					<Typography variant="body1" align="left" gutterBottom>
						Você deve ler todas as cláusulas dos contratos abaixo e aceitá-los
						para prosseguir
					</Typography>

					{!trial ? (
						<Typography variant="body1" align="left" gutterBottom>
							<FormControlLabel
								className={classes.formControlLabel}
								control={
									<Checkbox
										checked={contract1}
										className={classes.formControlLabelCheck}
										color="primary"
										onChange={async e => {
											const checked = e.target.checked
											dispatch(
												updateContract1(
													checked ? new Date().toISOString() : null
												)
											)
										}}
										name="acceptTerms"
									/>
								}
								label="Declaro ter lido e concordo com os Termos de Uso do serviço disponíveis no site&sup1;. Também declaro possuir acesso à rede de internet necessário para o correto funcionamento do sistema conforme os termos de uso, e plena capacidade civil para assinar este contrato. Por fim, entendo e aceito que a manutenção da linha telefônica contratada abaixo depende de contrato ativo para utilização da plataforma."
							/>
						</Typography>
					) : (
						<Typography variant="body1" align="left" gutterBottom>
							<FormControlLabel
								className={classes.formControlLabel}
								control={
									<Checkbox
										checked={contract1}
										className={classes.formControlLabelCheck}
										color="primary"
										onChange={async e => {
											const checked = e.target.checked
											dispatch(
												updateContract1(
													checked ? new Date().toISOString() : null
												)
											)
										}}
										name="acceptTerms"
									/>
								}
								label="Declaro ter lido e concordo com os Termos de Uso do serviço disponíveis no site&sup1;. Também declaro possuir acesso à rede de internet necessário para o correto funcionamento do sistema conforme os termos de uso, e plena capacidade civil para assinar este contrato."
							/>
						</Typography>
					)}

					<Typography
						variant="body1"
						align="left"
						gutterBottom
						className={classes.contract}
					>
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox
									checked={contract2}
									className={classes.formControlLabelCheck}
									color="primary"
									onChange={async e => {
										const checked = e.target.checked
										dispatch(
											updateContract2(checked ? new Date().toISOString() : null)
										)
									}}
									name="acceptTerms"
								/>
							}
							label="Declaro ter lido e entendido o Termo de Contratação de Prestação de Serviços&sup1;, 
                    registrado sob n° 95.471 junto ao 3° Registro de Títulos e Documentos e Civil das Pessoas Jurídicas de
                    Porto Alegre, sob os termos das Condições Gerais e/ou Contratuais e demais cláusulas
                    convencionadas, inseridas inclusive em seus anexos."
						/>
					</Typography>

					{!trial && (
						<Typography
							variant="body1"
							align="left"
							gutterBottom
							className={classes.contract}
						>
							<FormControlLabel
								className={classes.formControlLabel}
								control={
									<Checkbox
										checked={contract3}
										className={classes.formControlLabelCheck}
										color="primary"
										onChange={async e => {
											const checked = e.target.checked
											dispatch(
												updateContract3(
													checked ? new Date().toISOString() : null
												)
											)
										}}
										name="acceptTerms"
									/>
								}
								label="Declaro ter lido e entendido os Contratos de Prestação de Serviço de Telecomunicações&sup2;, devidamente registrados no 4° Cartório Oficial de Registro de Títulos e
                      Documentos de São Paulo, contratos STFC e SCM ambos registrados em 24 de novembro de 2015 sob os números 528.4807 e 528.4808,
                      respectivamente, concordando com todos os seus termos e condições, quando aplicável ao respectivo serviço contratado, assim como os detalhes do plano contratado&sup3;."
							/>
						</Typography>
					)}

					<Typography
						variant="body1"
						align="left"
						gutterBottom
						className={classes.ipAnnouncement}
					>
						Informamos que o seu endereço IP e porta de acesso{' '}
						{StoreOrder.remoteAddress} serão anexados ao seu pedido e utilizados
						legalmente como origem dessa solicitação.
					</Typography>

					<Typography variant="body1" align="left" className={classes.notes}>
						[1] Termos de uso do serviço e reprodução do Termo de Contratação de
						Prestação de Serviços registrado junto ao Registro de Títulos e
						Documentos: https://www.faleuniq.com.br/legal/BR-SERV-Plataforma.pdf
						&nbsp;
						{!trial && (
							<>
								[2]
								https://www.faleuniq.com.br/legal/providers/BRSPO-38-Contrato-STFC.pdf,
								https://www.faleuniq.com.br/legal/providers/BRSPO-38-Contrato-SCM.pdf
								[3] Plano SOHO inclui 1 linha telefônica e até 3 ramais com
								chamadas ilimitadas para telefones fixo e móvel nacional. Plano
								Office inclui 3 linhas telefônicas e até 10 ramais com chamadas
								ilimitadas para telefones fixo e móvel nacional. Plano Pro
								inclui 10 linhas telefônicas e até 30 ramais com chamadas
								ilimitadas para telefones fixo e móvel nacional. As contratadas,
								dentro das determinações legais e normativas aplicáveis, poderão
								suspender ou cancelar, imediatamente, o contrato e serviços,
								caso constatem consumo ou utilização fraudulenta e/ou indevida
								dos mesmos. Por uso indevido, considera-se a utilização para
								fins que não sejam de comunicação pessoal e individual, sendo
								vedada qualquer utilização para operações de call center,
								centrais de cobrança, dentre outros, número excessivo de
								diferentes destinos por dia, uso contínuo e ininterrupto por
								tempo fora do padrão de normalidade de uso do serviço,
								recebimento de chamadas em volume inferior a 33% do volume
								originado, ou uso superior a 1.000 (mil) minutos por linha
								telefônica contratada.
							</>
						)}
					</Typography>
				</Grid>
			</Box>
		</Paper>
	)
}

export default TermsOfUse
