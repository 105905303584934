import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFetch from 'use-http'
import { setSubscriber } from '../../../store/reducers/order'
import { Form } from '@unform/web'
import Input from '../../../components/input'
import gtag from 'ga-gtag'
import {
	makeStyles,
	Typography,
	Paper,
	Box,
	Button,
	AppBar,
	Toolbar,
	Grid,
	Container,
} from '@material-ui/core'
import bgImage from '../../../assets/image/bg.jpg'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		position: 'relative',
		backgroundColor: '#F4F4F4',
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	appbar: {
		backgroundColor: '#fff',
	},
	paper: {
		padding: '32px',
		position: 'absolute',
		width: '700px',
		top: '50%',
		right: '50%',
		transform: 'translate(50%,-50%)',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			position: 'static',
			height: 'auto',
			width: 'auto',
			transform: 'translate(0%,0%)',
			marginTop: '32px',
		},
	},
	toolbar: {
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 100,
		paddingLeft: 100,
	},
	media: {
		maxWidth: '100%',
	},
	successTitle: {
		width: '100%',
		fontSize: '34px',
		color: '#626262',
	},
	successRequestTitle: {
		fontSize: '14px',
		fontWeight: '700',
		color: '#626262',
	},
	successIcon: { margin: '20px' },
	successRequestNumber: {
		fontSize: '18px',
		fontWeight: '400',
		color: '#626262',
	},
	successRequestDescription: {
		fontSize: '16px',
		fontWeight: '400',
		color: '#626262',
		marginTop: '25px',
		textAlign: 'justify',
	},
	footer: {
		paddingLeft: '73px',
		paddingBottom: '28px',
		marginTop: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	footerText: {
		color: '#aaa',
		marginRight: '5px',
	},
	form: {
		color: '#626262',
		fontSize: '16px',
		marginTop: '55px',
	},
	button: {
		color: '#a2a2a2',
		border: '1px solid #a2a2a2',
		width: '110px',
	},
}))

const Coming = () => {
	const classes = useStyles()
	const formRef = useRef()
	const dispatch = useDispatch()
	const { put, response } = useFetch({})

	const { Order: StoreOrder } = useSelector(({ Order }) => {
		return { Order }
	})

	useEffect(() => {
		gtag('event', 'page_view', {
			send_to: 'G-Z7ZHCKVSNM',
			page_title: 'Coming soon',
			page_path: '/order/coming',
		})
	}, [])

	const handleFormSubmit = async data => {
		const waitingEmail = formRef?.current?.getFieldValue('email')
		const waitingPhone = formRef?.current?.getFieldValue('phone')

		if (_.isEmpty(waitingEmail)) {
			return
		}

		await put(`/public/orders/${StoreOrder.id}`, {
			waiting: {
				waitingEmail: waitingEmail,
				waitingPhone: waitingPhone,
			},
		})
		if (response.ok) {
			dispatch(setSubscriber({ waiting: true }))
		}
	}

	return (
		<Grid item xs={12} className={classes.root}>
			<AppBar position="static" color="default" className={classes.appbar}>
				<Toolbar className={classes.toolbar}>
					<img
						src="https://www.faleuniq.com.br/images/logo-01.png"
						alt="logo"
						style={{
							height: 50,
						}}
					/>
				</Toolbar>
			</AppBar>
			<main>
				<Container>
					<Paper className={classes.paper}>
						<Box>
							<Container>
								{!StoreOrder.subscriber?.waiting ? (
									<>
										<Typography variant="h4" gutterBottom>
											Ainda não chegamos na sua cidade!
										</Typography>
										<Typography className={classes.successRequestDescription}>
											Estamos constantemente expandindo nossa área de cobertura.
											Deixe abaixo seu e-mail que entraremos em contato assim
											que chegarmos na sua região.
										</Typography>
										<Form
											ref={formRef}
											onSubmit={handleFormSubmit}
											index={0}
											className={classes.form}
										>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={6}>
													<Input
														type="email"
														name="email"
														size="30"
														fullWidth={true}
														placeholder="E-mail"
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Input
														type="text"
														name="phone"
														size="15"
														fullWidth={true}
														placeholder="Telefone"
													/>
												</Grid>
												<Grid item xs={12}>
													<Button
														onClick={handleFormSubmit}
														className={classes.button}
													>
														Me avise!
													</Button>
												</Grid>
											</Grid>
										</Form>
									</>
								) : (
									<Grid align="center">
										<Typography
											style={{ marginTop: '120px', marginBottom: '120px' }}
											variant="h4"
											gutterBottom
										>
											Até logo!
										</Typography>
									</Grid>
								)}
							</Container>
						</Box>
					</Paper>
				</Container>
			</main>
		</Grid>
	)
}

export default Coming
