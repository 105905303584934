import React, { useEffect } from 'react'
import {
	AppBar,
	Toolbar,
	LinearProgress,
	Container,
	Grid,
	makeStyles,
	Box,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import City from './city'
import Provider from './provider'
import Plan from './plan'
import Numbers from './numbers'
import InfoFeatures from './infoFeatures'
import Subscriber from './subscriber'
import Tech from './tech'
import TermsOfUse from './termsOfUse'
import PictureSelf from './pictureSelf'
import PictureDocument from './pictureDocument'
import PicturePrepare from './picturePrepare'
import OrderSummary from './orderSummary'
import {
	navigate,
	createOrder,
	saveSubscriber,
	saveTech,
} from '../../../store/reducers/order'
import Next from '../../../components/next'
import Prev from '../../../components/prev'
import bgImage from '../../../assets/image/bg.jpg'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		position: 'relative',
		backgroundColor: '#F4F4F4',
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			minHeight: '100vh',
		},
	},
	appbar: {
		backgroundColor: '#fff',
	},
	appbarContent: {
		fontSize: '14px',
		width: '45px',
	},
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0',
		},
		[theme.breakpoints.up('md')]: {
			width: '100%',
			maxWidth: 'calc(100% - 340px)',
		},
		[theme.breakpoints.up('lg')]: {
			width: '100%',
			maxWidth: 'calc(100% - 360px)',
		},
	},
	toolbar: {
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 100,
		paddingLeft: 100,
	},
	main: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			gap: '5px',
			padding: '5px',
		},
	},
	footerLogo: {
		position: 'absolute',
		left: '73px',
		bottom: '28px',
		marginTop: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: '#aaa',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			left: '32px',
		},
	},
	footerImg: {
		paddingLeft: '5px',
	},
}))

const componentSteps = step => {
	switch (step) {
		case 'city':
			return <City />
		case 'provider':
			return <Provider />
		case 'plan':
			return <Plan />
		case 'numbers':
			return <Numbers />
		case 'infoFeatures':
			return <InfoFeatures />
		case 'subscriber':
			return <Subscriber />
		case 'tech':
			return <Tech />
		case 'termsOfUse':
			return <TermsOfUse />
		case 'picturePrepare':
			return <PicturePrepare />
		case 'pictureSelf':
			return <PictureSelf />
		case 'pictureDocument':
			return <PictureDocument />
		case 'orderSummary':
			return <OrderSummary />
		default:
			return <Box />
	}
}

const isEnable = store => {
	let ac = store.steps[store.stepActive - 1]
	return store.complete[ac]
}

const Step = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const StoreOrder = useSelector(state => state.Order)

	useEffect(() => {
		window.scroll(0, 0)
	}, [StoreOrder.stepActive])

	useEffect(() => {
		const previousStep = StoreOrder.steps[StoreOrder.stepActive - 2]
		switch (previousStep) {
			case 'subscriber':
				dispatch(saveSubscriber())
				break
			case 'tech':
				dispatch(saveTech())
				break
			default: // Nothing to do
		}
	}, [StoreOrder.stepActive, StoreOrder.steps, dispatch])

	useEffect(() => {
		dispatch(createOrder())
	}, [dispatch])

	useEffect(() => {
		if (StoreOrder.error) {
			history.push('/order/error')
		}
	}, [StoreOrder.error, history])

	const disabled = !(
		StoreOrder.stepActive < StoreOrder.steps.length && isEnable(StoreOrder)
	)

	const handleKeyPress = event => {
		if (event.which === 13 && !disabled && StoreOrder.stepActive !== 3) {
			dispatch(navigate('next'))
		}
	}

	if (StoreOrder.error) {
		return <></>
	}

	const loading = StoreOrder.loading

	return (
		<Grid item xs={12} className={classes.root} onKeyPress={handleKeyPress}>
			<AppBar position="static" color="default" className={classes.appbar}>
				<Toolbar className={classes.toolbar}>
					<img
						src="https://www.faleuniq.com.br/images/logo-01.png"
						alt="logo"
						style={{
							height: 50,
						}}
					/>
				</Toolbar>
			</AppBar>
			{loading ? (
				<LinearProgress />
			) : (
				<LinearProgress variant="determinate" value={StoreOrder.progress} />
			)}
			<main className={classes.main}>
				<Container className={classes.mainContainer}>
					{componentSteps(StoreOrder.steps[StoreOrder.stepActive - 1])}
				</Container>
				{StoreOrder.stepActive > 0 && StoreOrder.stepActive < 11 && (
					<Next disabled={disabled} />
				)}
				{StoreOrder.stepActive > 1 && (
					<Prev
						disabled={!(StoreOrder.stepActive > 1) || StoreOrder.efn === 'pm'}
					/>
				)}
			</main>
		</Grid>
	)
}

export default Step
