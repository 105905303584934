import React, { useMemo, useRef, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'

const loadScript = (src, position, id) => {
	if (!position) {
		return
	}
	const script = document.createElement('script')
	script.setAttribute('async', '')
	script.setAttribute('id', id)
	script.src = src
	position.appendChild(script)
}

const autocompleteService = { current: null }

const PlacesAutoComplete = ({ value: newValue, onChange, propsInput }) => {
	const [value, setValue] = useState(null)
	const [inputValue, setInputValue] = useState('')
	const [options, setOptions] = useState([])
	const loaded = useRef(false)

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				'https://maps.googleapis.com/maps/api/js?key=AIzaSyBqrvmsPISi158ohdnLBbAvR3Jd727cwr0&libraries=places&types=(cities)',
				document.querySelector('head'),
				'google-maps'
			)
		}
		loaded.current = true
	}

	const fetch = useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback)
			}, 200),
		[]
	)

	useEffect(() => {
		let active = true
		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}
		if (inputValue === '') {
			setOptions(value ? [value] : [])
			return undefined
		}
		fetch(
			{
				input: inputValue,
				types: ['(cities)'],
				componentRestrictions: { country: 'br' },
			},
			results => {
				if (active) {
					let newOptions = []
					if (value) {
						newOptions = [value]
					}
					if (results) {
						newOptions = [...newOptions, ...results]
					}
					setOptions(newOptions)
				}
			}
		)
		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	useEffect(() => {
		setValue(newValue)
	}, [newValue])

	return (
		<Autocomplete
			id="google-map-demo"
			getOptionLabel={option =>
				typeof option === 'string' ? option : option.description
			}
			filterOptions={x => x}
			options={options}
			autoComplete
			disableClearable
			includeInputInList
			filterSelectedOptions
			freeSolo={true}
			value={value}
			margin="normal"
			fullWidth
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options)
				setValue(newValue)
				onChange({ id: newValue.place_id, name: newValue.description })
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			renderInput={params => (
				<TextField
					{...params}
					{...propsInput}
					autoFocus
					margin="normal"
					placeholder="Digite sua cidade"
					fullWidth
				/>
			)}
			renderOption={option => {
				const matches =
					option.structured_formatting.main_text_matched_substrings
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map(match => [match.offset, match.offset + match.length])
				)

				return (
					<Grid
						//onClick={() => onChange(option.place_id)}
						container
						alignItems="center"
					>
						<Grid item></Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{ fontWeight: part.highlight ? 700 : 400 }}
								>
									{part.text}
								</span>
							))}

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	)
}

export default PlacesAutoComplete
