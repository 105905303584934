import React, { useEffect } from 'react'
import {
	Typography,
	Paper,
	makeStyles,
	Box,
	Container,
} from '@material-ui/core'
import PlacesAutoComplete from '../../../../components/placesAutoComplete'
import {
	updateCity,
	setCityClue,
	navigate,
} from '../../../../store/reducers/order'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
	paper: {
		padding: 32,
		width: '413px',
		heigth: '201px',
		borderRadius: '10px',
		border: '1px solid #d3d3d3',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
			minWidth: '100%',
		},
	},
	rootPlaces: {
		marginTop: 30,
		marginBottom: 30,
	},
	cityContainer: {
		padding: 0,
		height: '100%',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	},
}))

const City = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { Order: StoreOrder } = useSelector(({ Order }) => {
		return { Order }
	})

	useEffect(() => {
		if (StoreOrder.cityClue) {
			dispatch(
				updateCity({
					id: StoreOrder.cityClue.id,
					name: StoreOrder.cityClue.name,
				})
			)
			dispatch(setCityClue(null))
			dispatch(navigate('next'))
		}
	}, [StoreOrder.cityClue, dispatch])

	return StoreOrder?.cityClue ? (
		<></>
	) : (
		<Paper className={classes.paper}>
			<Container className={classes.cityContainer}>
				<Typography variant="h4">Qual sua cidade?</Typography>
				<Box className={classes.rootPlaces}>
					<PlacesAutoComplete
						value={StoreOrder?.city?.name || ''}
						onChange={async e => {
							dispatch(
								updateCity({
									id: e.id,
									name: e.name,
								})
							)
						}}
					/>
				</Box>
			</Container>
		</Paper>
	)
}

export default City
