import { useEffect, useState } from 'react'
import {
	makeStyles,
	Typography,
	Paper,
	Grid,
	Button,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Box,
	Modal,
	TextField,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
	applyCoupon,
	setCouponError,
	createSubscription,
	finish,
} from '../../../../store/reducers/order'
import { parsePhoneNumber } from 'libphonenumber-js'

const useStyles = makeStyles(theme => ({
	paper: {
		width: '100%',
		overflowY: 'auto',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
	},
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(12, 1fr)',
		gridTemplateRows: 'repeat(4, 1fr)',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: 'repeat(1, 1fr)',
			gridTemplateRows: 'repeat(1, 1fr)',
		},
	},
	itemColumnExpanded: {
		gridColumnEnd: 'span 6',
		gridRowEnd: 'span 1',
		[theme.breakpoints.down('md')]: {
			gridColumnEnd: 'span 1',
			gridRowEnd: 'span 1',
			borderRight: 'none',
		},
	},
	item: {
		gridColumnEnd: 'span 3',
		gridRowEnd: 'span 1',
		[theme.breakpoints.down('md')]: {
			gridColumnEnd: 'span 1',
			gridRowEnd: 'span 1',
		},
	},
	itemColumnRowExpanded: {
		gridColumnEnd: 'span 6',
		gridRowEnd: 'span 2',
		[theme.breakpoints.down('md')]: {
			gridColumnEnd: 'span 1',
			gridRowEnd: 'span 1',
			borderRight: 'none',
		},
	},
	itemRowExpanded: {
		gridColumnEnd: 'span 3',
		gridRowEnd: 'span 2',
		[theme.breakpoints.down('md')]: {
			gridColumnEnd: 'span 1',
			gridRowEnd: 'span 1',
		},
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
	},
	orderSummaryTitle: {
		color: '#626262',
	},
	orderSummaryItem: {},
	orderSummaryItemTitle: {
		fontSize: '10px',
		fontWeight: 'bold',
		color: '#626262',
	},
	orderSummaryItemDescription: {
		fontSize: '14px',
		fontWeight: 'normal',
		color: '#626262',
	},
	orderSummaryNumberLists: {
		maxHeight: '100px',
		overflowY: 'auto',
		marginRight: '20px',
	},
	orderSummaryNumberListsItem: {
		fontSize: '14px',
		fontWeight: 'normal',
		color: '#626262',
	},
	orderSummaryNumberListsTitle: {
		fontSize: '10px',
		fontWeight: 'bold',
		color: '#626262',
		marginTop: '25px',
	},
	orderSummaryValueGrid: {
		paddingTop: '10px',
		marginTop: '10px',
		borderTop: '1px dashed #D3D3D3',
	},
	orderSummaryValueContainer: {
		lineHeight: 'normal',
	},
	orderSummaryValue: {
		fontWeight: '500',
		fontSize: '32px',
		color: theme.palette.primary.main,
	},
	orderSummaryValueDetail: {
		fontSize: '12px',
		color: theme.palette.primary.main,
	},
	orderSummaryButton: {
		backgroundColor: theme.palette.primary.main,
		height: '100%',
		textTransform: 'none',
		fontSize: '14px',
		fontWeight: '500',
		padding: '16px 24px',
	},
	choosenTable: {
		border: 'none',
		height: '100%',
		paddingLeft: '10px',
		paddingRight: '10px',
		display: 'flex',
		overflow: 'auto',
		maxHeight: '100px',
	},
	helperMarginLeft: {
		marginLeft: '20px',
		[theme.breakpoints.down('md')]: {
			marginLeft: '0px',
		},
	},
	helperMarginBottom: {
		marginBottom: '0px',
		[theme.breakpoints.down('md')]: {
			marginBottom: '15px',
		},
	},
	orderSummaryModal: {
		position: 'absolute',
		top: '50%',
		right: '50%',
		transform: 'translate(50%,-50%)',
		width: 400,
		backgroundColor: '#fff',
		padding: theme.spacing(2, 4, 3),
	},
	orderSummaryModalTitle: {
		color: '#ff0000',
		fontSize: '20px',
		textAlign: 'center',
	},
	choosenTableCell: {
		padding: '0px',
		borderBottom: '0px',
	},
	couponContainer: {
		paddingTop: '10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		fontSize: '10px',
		fontWeight: 'normal',
		color: '#626262',
		height: '40px',
	},
	couponInput: {
		fontSize: '12px',
	},
	couponButton: {
		marginLeft: '10px',
		fontSize: '10px',
		[theme.breakpoints.down('md')]: {
			marginTop: '2px',
		},
	},
	couponError: {
		marginLeft: '10px',
		color: '#F44336',
	},
	couponShow: {
		cursor: 'pointer',
		fontSize: '10px',
		textDecoration: 'underline',
	},
}))

const OrderSummary = () => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)
	const [showCouponInput, setShowCouponInput] = useState(false)
	const [couponId, setCouponId] = useState('')
	const dispatch = useDispatch()
	const history = useHistory()
	const StoreOrder = useSelector(state => state.Order)
	const StorePayment = useSelector(state => state.Payment)

	const closeModal = () => {
		setOpen(false)
	}

	const trial = StoreOrder.plan?.price === 0

	useEffect(() => {
		if (StoreOrder.complete.summary) {
			dispatch(finish(StoreOrder.id))
			history.push('/order/success')
		}
	}, [StoreOrder, history, dispatch])

	const numbers = new Map([...StoreOrder.numbersNew, ...StoreOrder.numbersLNP])
	const numberRange = (number, qty) => {
		return parseInt(number.substring(number.length - 4)) + parseInt(qty) - 1
	}

	const checkout = () => {
		if (trial) {
			dispatch(createSubscription())
			return
		}

		let ck = new window.PagarMeCheckout.Checkout({
			encryption_key: StorePayment?.cryptography,
			success: function (data) {
				dispatch(createSubscription(data))
			},
			error: function (err) {
				console.log(JSON.stringify(err))
			},
			close: function () {
				console.log('Payment aborted')
			},
		})
		ck.open({
			amount: Math.trunc(StoreOrder.plan?.price * 100),
			buttonText: 'Pagar',
			customerData: 'false',
			createToken: 'false',

			customerName: StoreOrder.subscriber.name,
			customerDocumentNumber: StoreOrder.subscriber.cnpj,
			customerEmail: StoreOrder.subscriber.contactEmail,

			paymentMethods: 'credit_card, boleto',
			items: [
				{
					id: StoreOrder.plan?._id,
					title: StoreOrder.plan?.name,
					unit_price: Math.trunc(StoreOrder.plan?.price * 100),
					quantity: 1,
					tangible: 'false',
				},
			],
		})
	}

	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})

	const changePaymentMethod = StoreOrder.efn === 'pm'

	let orderButtonText
	if (changePaymentMethod) orderButtonText = 'Alterar método de pagamento'
	else if (trial) orderButtonText = 'Finalizar'
	else orderButtonText = 'Finalizar pedido'

	let priceRecurrent = null
	let priceFirst = null

	if (!changePaymentMethod) {
		priceRecurrent = StoreOrder.plan.price
		priceFirst = 0

		if (!trial && StoreOrder.coupon) {
			if (StoreOrder.coupon.type === 'RECURRENT') {
				priceRecurrent =
					priceRecurrent - priceRecurrent * (StoreOrder.coupon.discount / 100)
			} else {
				priceFirst = priceRecurrent
				priceFirst =
					priceFirst - priceFirst * (StoreOrder.coupon.discount / 100)
			}
		}
	}

	return (
		<Paper className={classes.paper}>
			<Box className={classes.cardContainer}>
				<Typography
					variant="h4"
					component="h6"
					gutterBottom
					className={classes.orderSummaryTitle}
				>
					{changePaymentMethod ? <>Pagamento</> : <>Resumo do Pedido</>}
				</Typography>
				<Box className={classes.container}>
					<Box className={classes.itemColumnExpanded}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginBottom)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								Cidade de contratação
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.city.name ? StoreOrder.city.name : ''}
							</Typography>
						</Box>
					</Box>
					<Box className={classes.item}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginLeft)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								{StoreOrder.subscriber.cnpj ? 'CNPJ' : 'CPF'}
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.cnpj
									? StoreOrder.subscriber.cnpj
									: StoreOrder.subscriber.cpf}
							</Typography>
						</Box>
					</Box>
					<Box className={classes.item}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginBottom)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								Razão Social
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.name ? StoreOrder.subscriber.name : '-'}
							</Typography>
						</Box>
					</Box>
					{!changePaymentMethod ? (
						<Box className={classes.itemColumnExpanded}>
							<Box
								className={
									(classes.orderSummaryItemContainer,
									classes.helperMarginBottom)
								}
							>
								<Typography className={classes.orderSummaryItemTitle}>
									Plano
								</Typography>
								<Typography className={classes.orderSummaryItemDescription}>
									{StoreOrder.plan.name}
								</Typography>
								<small>
									<span>até {StoreOrder.plan.seats} </span>
									<span>usuários/ramais</span>
									<br />
									<span>{StoreOrder.plan.dids} </span>
									<span>
										{StoreOrder.plan.dids > 1
											? 'linhas telefônicas'
											: 'linha telefônica'}
									</span>
								</small>
							</Box>
						</Box>
					) : (
						<Box className={classes.itemColumnExpanded}></Box>
					)}
					<Box className={classes.item}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginLeft)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								{StoreOrder.subscriber.contactName ? 'Nome do Contato' : 'Nome'}
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.contactName
									? StoreOrder.subscriber.contactName
									: StoreOrder.subscriber.name}
							</Typography>
						</Box>
					</Box>
					<Box className={classes.item}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginBottom)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								{StoreOrder.subscriber.contactEmail
									? 'Email do Contato'
									: 'Email'}
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.contactEmail
									? StoreOrder.subscriber.contactEmail
									: StoreOrder.subscriber.email}
							</Typography>
						</Box>
					</Box>
					{!changePaymentMethod ? (
						<Box className={classes.itemColumnRowExpanded}>
							<Box
								className={
									(classes.orderSummaryItemContainer,
									classes.helperMarginBottom)
								}
							>
								<Typography className={classes.orderSummaryNumberListsTitle}>
									Números selecionados
								</Typography>
								<Box className={classes.choosenTable}>
									<Table aria-label="simple table">
										<TableBody>
											{[...numbers].map(([key, value], index) => {
												return (
													<TableRow key={index}>
														<TableCell
															component="th"
															scope="row"
															className={classes.choosenTableCell}
														>
															{value.number
																? parsePhoneNumber(
																		value.number,
																		'BR'
																  ).formatNational()
																: ''}{' '}
															{value.qty && value.qty > 1
																? `até ${numberRange(
																		value.number,
																		value.qty
																  )} (${value.qty} números)`
																: ''}
														</TableCell>
													</TableRow>
												)
											})}
										</TableBody>
									</Table>
								</Box>
							</Box>
						</Box>
					) : (
						<Box className={classes.itemColumnRowExpanded}></Box>
					)}
					<Box className={classes.itemRowExpanded}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginLeft)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								Endereço
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.address
									? StoreOrder.subscriber.address
									: ''}{' '}
								{StoreOrder.subscriber.number
									? StoreOrder.subscriber.number
									: ''}
								{StoreOrder.subscriber.complement
									? ', ' + StoreOrder.subscriber.complement
									: ''}
								<br />{' '}
								{StoreOrder.subscriber.cep ? StoreOrder.subscriber.cep : ''}
							</Typography>
						</Box>
					</Box>
					<Box className={classes.item}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginBottom)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								Cidade
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.city ? StoreOrder.subscriber.city : ''}
							</Typography>
						</Box>
					</Box>
					<Box className={classes.item}>
						<Box
							className={
								(classes.orderSummaryItemContainer, classes.helperMarginBottom)
							}
						>
							<Typography className={classes.orderSummaryItemTitle}>
								Estado
							</Typography>
							<Typography className={classes.orderSummaryItemDescription}>
								{StoreOrder.subscriber.state ? StoreOrder.subscriber.state : ''}
							</Typography>
						</Box>
					</Box>
				</Box>

				{!trial && !changePaymentMethod && (
					<Grid container className={classes.couponContainer}>
						{!StoreOrder.coupon && !showCouponInput && (
							<Box
								className={classes.couponShow}
								onClick={event => {
									setShowCouponInput(true)
								}}
							>
								Tenho um cupom
							</Box>
						)}

						{StoreOrder.coupon && (
							<Box>O cupom {StoreOrder.coupon._id} foi selecionado</Box>
						)}

						{!StoreOrder.coupon && showCouponInput && (
							<>
								<TextField
									InputProps={{
										classes: {
											input: classes.couponInput,
										},
									}}
									placeholder="Insira um cupom"
									value={couponId}
									onChange={event => {
										dispatch(setCouponError(false))
										setCouponId(
											event.target.value
												.replace(/[^a-zA-Z0-9]+/, '')
												.toUpperCase()
										)
									}}
								></TextField>
								<Button
									className={classes.couponButton}
									variant="contained"
									onClick={event => {
										dispatch(applyCoupon(couponId))
									}}
								>
									Adicionar cupom
								</Button>

								{StoreOrder.couponError && (
									<Grid className={classes.couponError}>
										O cupom é inválido
									</Grid>
								)}
							</>
						)}
					</Grid>
				)}

				<Grid
					container
					justify="space-between"
					className={classes.orderSummaryValueGrid}
				>
					{!changePaymentMethod && (
						<Grid item>
							<Grid>
								<Typography className={classes.orderSummaryItemTitle}>
									Total
								</Typography>
								<Box
									className={
										(classes.orderSummaryValueContainer,
										classes.helperMarginBottom)
									}
								>
									{!trial ? (
										<>
											<span className={classes.orderSummaryValue}>
												{formatter.format(priceRecurrent)}
											</span>
											<span className={classes.orderSummaryValueDetail}>
												/mês
											</span>
										</>
									) : (
										<>
											<span className={classes.orderSummaryValue}>
												Grátis por 14 dias
											</span>
										</>
									)}
								</Box>
							</Grid>
						</Grid>
					)}

					{priceFirst > 0 && (
						<Grid item>
							<Grid>
								<Typography className={classes.orderSummaryItemTitle}>
									Primeiro mês
								</Typography>
								<Box
									className={
										(classes.orderSummaryValueContainer,
										classes.helperMarginBottom,
										classes.orderSummaryValue)
									}
								>
									{formatter.format(priceFirst)}
								</Box>
							</Grid>
						</Grid>
					)}

					<Grid item>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							className={classes.orderSummaryButton}
							onClick={checkout}
						>
							{orderButtonText}
						</Button>
					</Grid>
				</Grid>
			</Box>
			<Modal
				open={open}
				onClose={closeModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<Paper className={classes.orderSummaryModal}>
					<Typography className={classes.orderSummaryModalTitle}>
						Erro ao processar pedido
					</Typography>
				</Paper>
			</Modal>
		</Paper>
	)
}

export default OrderSummary
