import React from 'react'
import Scenes from './scenes'
import Store from './store'
import Theme from './theme'
import Http from './http'
import ptBR from './translations/pt-BR'
import I18n from 'i18n-js'
import ErrorBoundary from './ErrorBoundary'

const App = () => {
	I18n.translations = {
		'pt-BR': ptBR,
	}
	I18n.defaultLocale = 'pt-BR'
	I18n.locale = 'pt-BR'
	return (
		<Store>
			<ErrorBoundary>
				<Theme>
					<Http>
						<Scenes />
					</Http>
				</Theme>
			</ErrorBoundary>
		</Store>
	)
}

export default App
