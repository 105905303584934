import React, { useEffect } from 'react'
import {
	Typography,
	Paper,
	Grid,
	CardContent,
	Button,
	Card,
	makeStyles,
	Divider,
	Box,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
	navigate,
	setPlanClue,
	loadPlans,
	updatePlan,
} from '../../../../store/reducers/order'
import { isMobile } from 'react-device-detect'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		overflow: 'hidden',
	},
	paper: {
		padding: 32,
		width: '952px',
		height: '560px',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
		},
	},
	media: {
		height: 100,
		width: '100%',
	},
	gridContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		gridAutoRows: '1fr',
		columnGap: '15px',
		overflow: 'auto',
		height: '100%',
		marginTop: '25px',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: 'repeat(2, 1fr)',
		},
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: 'repeat(1, 1fr)',
		},
	},
	card: {
		height: '420px',
		width: '210px',
		border: '2px solid #F4F4F4',
		borderRadius: '10px',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto',
		},
	},
	gridRoot: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '85px',
		},
	},
	planTitle: {
		fontWeight: 400,
		fontSize: '34px',
		lineHeight: '48px',
		paddingBottom: '24px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '32px',
			fontSize: '26px',
		},
	},
	planCardContent: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	planCardTitle: {
		fontSize: '20px',
		color: '#626262',
		fontWeight: 500,
	},
	planPrice: {
		fontSize: '30px',
		fontWeight: 500,
		marginBottom: '20px',
	},
	planHighlights: {
		fontSize: '16px',
		color: '#626262',
	},
	planFeatures: {
		minHeight: '100px',
		maxHeight: '100px',
		marginTop: '20px',
	},
	planFeature: {
		fontSize: '11px',
		color: '#626262',
	},
	planInformation: {
		paddingTop: '20px',
		paddingBottom: '15px',
		fontSize: '10px',
		lineHeight: '13.3px',
		color: '#626262',
	},
	planCardButtonDetail: {
		marginTop: 'auto',
	},
	planCardButton: {
		'&:hover': {
			backgroundColor: '#fbfbfb',
		},
		height: '50px',
		textTransform: 'none',
		fontSize: '16px',
		fontWeight: 500,
	},
}))

const Plan = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const StoreOrder = useSelector(state => state.Order)
	const plans = useSelector(state => state.Plan.list)

	useEffect(() => {
		// Load plans
		if (plans.length === 0) {
			dispatch(loadPlans())
		}
		// Use clue
		if (StoreOrder.planClue && plans.length > 0) {
			const plan = _.find(plans, { code: StoreOrder.planClue.code })
			dispatch(
				updatePlan({
					_id: plan._id,
					price: plan.price,
					code: plan.code,
					description: plan.description,
					seats: plan.seats,
					name: plan.name,
					dids: plan.dids,
					features: plan.features,
					information: plan.information,
					color: plan.color,
				})
			)
			dispatch(setPlanClue(null))
		}
	}, [StoreOrder.planClue, plans, dispatch])

	var formatter = new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})

	if (StoreOrder?.planClue || plans.length === 0) {
		return <></>
	}

	return (
		<>
			<Paper className={classes.paper}>
				<Box className={classes.gridRoot}>
					<Typography variant="h4">Selecione o plano desejado</Typography>
					<Grid className={classes.gridContainer}>
						{plans &&
							plans.map(
								(
									{
										_id,
										price,
										code,
										description,
										seats,
										name,
										dids,
										features,
										information,
										color,
									},
									index
								) => {
									const disabled = StoreOrder.rc && price === 0

									return (
										<Grid item key={index}>
											<Card
												style={
													StoreOrder.plan?._id === _id
														? {
																borderWidth: 2,
																borderColor: color,
																borderStyle: 'solid',
														  }
														: {}
												}
												className={classes.card}
											>
												<CardContent className={classes.planCardContent}>
													<Divider
														style={{
															width: '40px',
															border: '1px solid ',
															borderColor: color,
														}}
													/>
													<Typography
														gutterBottom
														className={classes.planCardTitle}
														component="h2"
													>
														{name}
													</Typography>
													<Typography
														gutterBottom
														className={classes.planPrice}
														component="h5"
														style={{ color: color }}
													>
														<sup style={{ fontSize: '8px' }}>R$</sup>{' '}
														{formatter.format(price)}
													</Typography>
													<Typography
														gutterBottom
														className={classes.planHighlights}
														component="p"
													>
														{seats} usuários / ramais
													</Typography>
													<Typography
														gutterBottom
														className={classes.planHighlights}
														component="p"
													>
														{dids}{' '}
														{dids === 1
															? 'linha telefônica'
															: 'linhas telefônicas'}
													</Typography>
													<Grid className={classes.planFeatures}>
														{features.map((feature, index) => {
															return (
																<Typography
																	key={index}
																	gutterBottom
																	className={classes.planFeature}
																	component="p"
																>
																	{feature}
																</Typography>
															)
														})}
													</Grid>
													<Grid className={classes.planCardButtonDetail}>
														<Typography
															gutterBottom
															className={classes.planInformation}
															component="p"
														>
															* {information}
														</Typography>
														<Button
															className={classes.planCardButton}
															disabled={disabled}
															onClick={async () => {
																dispatch(
																	updatePlan({
																		_id,
																		price,
																		code,
																		description,
																		seats,
																		name,
																		dids,
																		features,
																		information,
																		color,
																	})
																)

																// Force Next/Previous buttons to be on screen
																//  after plan selection. This is a quick/ugly
																//  workaround to a (IMO) UX flaw
																if (isMobile) {
																	window.scrollTo(0, document.body.scrollHeight)
																}
															}}
															fullWidth={true}
															variant="outlined"
															style={
																StoreOrder.plan?._id === _id
																	? {
																			color: '#ffffff',
																			border: `1px solid ${color}`,
																			backgroundColor: color,
																	  }
																	: {
																			color: color,
																			border: `1px solid ${color}`,
																	  }
															}
														>
															Selecionar
														</Button>
													</Grid>
												</CardContent>
											</Card>
										</Grid>
									)
								}
							)}
					</Grid>
				</Box>
			</Paper>
		</>
	)
}

export default Plan
