import React from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from '../../store/reducers/order'
import { Button, Box, makeStyles } from '@material-ui/core'
import { ArrowForwardRounded as IconArrowForwardRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			position: 'static',
			flex: 0,
		},
		[theme.breakpoints.up('md')]: {
			right: 60,
		},
		[theme.breakpoints.up('lg')]: {
			right: 104,
		},
	},
	selectedButton: {
		'&:hover': {
			boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
			backgroundColor: '#fff',
			color: '#444',
		},
		fontSize: 18,
		boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
		backgroundColor: '#fff',
		color: theme.palette.primary.main,
		borderRadius: '26px',
		fontStyle: 'normal',
		fontWeight: '500',
		letterSpacing: 0,
		textAlign: 'center',
		transition: 'all .1s',
		padding: '8px 16px',
		textTransform: 'none',
	},
	selectedArrow: {
		color: theme.palette.primary.main,
		fontSize: '40px !important',
		borderRadius: 100,
		borderColor: theme.palette.primary.main,
		borderWidth: '3px',
		borderStyle: 'solid',
		marginLeft: 15,
	},
	defaultButton: {
		backgroundColor: 'transparent',
		color: '#000000',
		padding: '8px 16px',
	},
	defaultArrow: {
		color: 'rgba(255, 255, 255, 0.30)',
		fontSize: '40px !important',
		borderRadius: 100,
		borderColor: 'rgba(255, 255, 255, 0.30)',
		borderWidth: '3px',
		borderStyle: 'solid',
		marginLeft: 15,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
}))

const Next = ({ disabled }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	return (
		<Box className={classes.root}>
			<Button
				disabled={disabled}
				className={disabled ? classes.defaultButton : classes.selectedButton}
				onClick={() => {
					dispatch(navigate('next'))
				}}
				endIcon={
					<IconArrowForwardRounded
						className={disabled ? classes.defaultArrow : classes.selectedArrow}
					/>
				}
			>
				{!disabled && 'Continuar'}
			</Button>
		</Box>
	)
}

export default Next
