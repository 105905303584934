const Types = {
	LIST: 'reducer/number/@LIST',
	NONUMBERS: 'reducer/number/@NONUMBERS',
}

export const setNumbers = numbers => {
	return {
		type: Types.LIST,
		payload: numbers,
	}
}

const InitialState = {
	list: [],
}

const Number = (state = InitialState, action) => {
	switch (action.type) {
		case Types.LIST:
			return { ...state, list: action.payload }
		default:
			return state
	}
}

export default Number
