import React, { useEffect, useState, useRef } from 'react'
import { TextField } from '@material-ui/core'
import { useField } from '@unform/core'
import InputMask from 'react-input-mask'
import _ from 'lodash'

const INPUT_TYPES = [
	{ type: 'cpnj', mask: '99.999.999/9999-99' },
	{ type: 'cpf', mask: '999.999.999-99' },
	{ type: 'date', mask: '99/99/9999' },
	{ type: 'zipcode', mask: '99999-999' },
	{ type: 'phone' },
]

const Input = ({ name, type, ...rest }) => {
	const inputRef = useRef(null)
	const inputRefMask = useRef(null)
	const { fieldName, defaultValue, registerField, error } = useField(name)
	const [phoneMask, setPhoneMask] = useState('(99) 99999-9999')
	const inputType = _.head(_.filter(INPUT_TYPES, { type: type }))

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: inputRef,
			getValue: ref => {
				return ref.current.value
			},
			setValue: (ref, value) => {
				ref.current.value = value
				if (inputRefMask) {
					inputRefMask?.current?.setInputValue(value)
				}
			},
			clearValue: ref => {
				ref.current.value = ''
				if (inputRefMask) {
					inputRefMask?.current?.setInputValue('')
				}
			},
		})
	}, [fieldName, registerField])

	if (!inputType) {
		const { error } = rest
		delete rest.error
		return (
			<TextField
				inputRef={inputRef}
				defaultValue={defaultValue}
				error={error && error.length > 0}
				helperText={error && error}
				inputProps={{ style: { fontSize: '16px' } }}
				{...rest}
			/>
		)
	} else if (_.isEqual(inputType.type, 'phone')) {
		let restOnBlur = rest.onBlur
		rest.onBlur = undefined
		return (
			<InputMask
				mask={phoneMask}
				{...rest}
				onBlur={e => {
					restOnBlur()
					if (e.target.value.replace('_', '').length === 14) {
						setPhoneMask('(99) 9999-9999')
					}
				}}
				onFocus={e => {
					if (e.target.value.replace('_', '').length === 14) {
						setPhoneMask('(99) 99999-9999')
					}
				}}
				defaultValue={defaultValue}
				ref={inputRefMask}
			>
				{() => (
					<TextField
						inputRef={inputRef}
						error={error}
						helperText={error && error}
						placeholder={rest.placeholder}
						inputProps={{ style: { fontSize: '16px' } }}
						fullWidth
					/>
				)}
			</InputMask>
		)
	} else {
		const { error } = rest
		return (
			<InputMask
				mask={inputType.mask}
				ref={inputRefMask}
				defaultValue={defaultValue}
				{...rest}
			>
				{() => (
					<TextField
						inputRef={inputRef}
						error={error}
						helperText={error && error}
						placeholder={rest.placeholder}
						inputProps={{ style: { fontSize: '16px' } }}
						fullWidth
					/>
				)}
			</InputMask>
		)
	}
}

export default Input
