import React from 'react'
import { makeStyles, Typography, Paper, Box } from '@material-ui/core'
import instructionImage from '../../../../assets/image/pictureSelfInstruction.png'

const useStyles = makeStyles(theme => ({
	paper: {
		paddingTop: '25px',
		paddingBottom: '25px',
		paddingLeft: '32px',
		paddingRight: '32px',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
	},
	media: {
		maxWidth: '100%',
	},
	didsTitle: {
		width: '0',
		minWidth: '100%',
	},
	didsDescription: {
		width: '0',
		minWidth: '100%',
		lineHeight: '19px',
		textAlign: 'justify',
	},
}))

const PicturePrepare = () => {
	const classes = useStyles()

	return (
		<Paper className={classes.paper}>
			<Box>
				<Typography
					className={classes.didsTitle}
					align="left"
					variant="h4"
					gutterBottom
				>
					Falta pouco!
				</Typography>
				<Typography
					className={classes.didsDescription}
					variant="subtitle1"
					gutterBottom
				>
					Para sua segurança e por exigências regulatórias, precisamos de uma
					foto sua segurando um{' '}
					<strong>documento de identificação válido</strong> que{' '}
					<strong>conste o seu CPF</strong>. Por favor tenha o seu documento em
					mãos e avance para o próximo passo para capturar a imagem.
				</Typography>
				<img className={classes.media} src={instructionImage} alt="" />
			</Box>
		</Paper>
	)
}

export default PicturePrepare
