import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme, CssBaseline } from '@material-ui/core'

const Theme = ({ children }) => {
	return (
		<ThemeProvider
			theme={createMuiTheme({
				palette: {
					type: 'light',
				},
				typography: {
					h4: {
						fontSize: '2rem',
					},
					fontFamily: ['Rubik', 'sans-serif'].join(','),
				},
			})}
		>
			<CssBaseline />
			{children}
		</ThemeProvider>
	)
}

export default Theme
