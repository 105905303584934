export const Types = {
	TOKEN: 'reducer/auth/@TOKEN',
	AUTHENTICATION: 'reducer/auth/@AUTHENTICATION',
}

const InitialState = {
	token: '',
	role: '',
}

const Auth = (state = InitialState, action) => {
	switch (action.type) {
		case Types.TOKEN:
			return { ...state, token: action.payload }
		case Types.AUTHENTICATION:
			return {
				...state,
				...action.payload,
			}
		default:
			return state
	}
}

export default Auth
