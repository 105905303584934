import React, { useEffect, useCallback } from 'react'
import {
	makeStyles,
	Typography,
	Grid,
	Paper,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	LinearProgress,
	Box,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { setProvider } from '../../../../store/reducers/order'
import { Types as TypesProvider } from '../../../../store/reducers/provider'
import { setCryptography, setAPI } from '../../../../store/reducers/payment'
import useFetch from 'use-http'

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		overflow: 'hidden',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: 32,
		width: '998px',
		height: '518px',
		borderRadius: '10px',
		border: '1px solid #d3d3d3',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			height: '100vh',
			width: '100vw',
		},
	},
	providerTitle: {
		paddingBottom: '24px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '26px',
		},
		[theme.breakpoints.down('sm')]: { paddingTop: '24px' },
	},
	media: {
		borderRadius: '5px',
		width: '150px',
		height: '91px',
		margin: '16px auto',
	},
	gridContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridAutoRows: '1fr',
		columnGap: '10px',
		rowGap: '10px',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: 'repeat(3, 1fr)',
			columnGap: '10px',
			rowGap: '10px',
		},
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: 'repeat(2, 1fr)',
			columnGap: '10px',
			rowGap: '10px',
		},
		'@media (max-width:450px)': { gridTemplateColumns: 'repeat(1, 1fr)' },
	},
	gridItem: {
		'@media (max-width:450px)': { margin: '0 auto' },
	},
	card: {
		padding: 0,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			height: '199px',
			width: '182px',
		},
		'& button': {
			height: '100%',
		},
	},
	cardItem: {
		padding: 0,
	},
	gridRoot: {
		overflowY: 'auto',
		overflowX: 'hidden',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '85px',
		},
	},
	providerCardContent: {
		padding: '0 16px 16px 16px',
	},
	providerCardDescription: {
		fontSize: '16px',
		lineHeight: '24px',
		color: '#626262',
		fontWeight: 'normal',
	},
	providerCardTitle: {
		fontSize: '18px',
		lineHeight: '24px',
		color: '#212121',
	},
}))

const Provider = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { get, loading, error, response, put } = useFetch({})

	const { Order: StoreOrder, Provider: StoreProvider } = useSelector(
		({ Order, Provider }) => {
			return { Order, Provider }
		}
	)

	const getProvider = useCallback(async () => {
		const providers = await get('/public/providers')
		if (response.ok) {
			dispatch({
				type: TypesProvider.LIST,
				payload: providers,
			})
		}
	}, [get, dispatch, response])

	useEffect(() => {
		getProvider()
	}, [getProvider])
	return (
		<Paper className={classes.paper}>
			{loading && <LinearProgress />}
			{error && 'Error'}
			<Typography align="left" variant="h4" className={classes.providerTitle}>
				Operadoras disponíveis na localidade selecionada
			</Typography>
			<Box className={classes.gridRoot}>
				<Grid container className={classes.gridContainer} spacing={1}>
					{StoreProvider.list &&
						StoreProvider.list.map(
							({ _id, name, description, payment }, index) => {
								return (
									<Grid item key={index} className={classes.gridItem}>
										<Card
											style={
												StoreOrder.provider === _id
													? {
															borderWidth: 2,
															borderColor: '#0F94DA',
															borderStyle: 'solid',
													  }
													: {}
											}
											className={classes.card}
										>
											<CardActionArea
												onClick={async () => {
													await put(`/public/orders/${StoreOrder.id}`, {
														provider: _id,
													})
													if (response.ok) {
														dispatch(setProvider(_id))
														dispatch(setAPI(payment?.api))
														dispatch(setCryptography(payment?.cryptography))
													}
												}}
											>
												<CardMedia
													className={classes.media}
													image="https://source.unsplash.com/user/erondu"
													title="Contemplative Reptile"
												/>
												<CardContent className={classes.providerCardContent}>
													<Typography
														variant="h6"
														component="h6"
														className={classes.providerCardDescription}
													>
														Operadora
													</Typography>
													<Typography
														variant="h6"
														component="h6"
														className={classes.providerCardTitle}
													>
														{name}
													</Typography>
												</CardContent>
											</CardActionArea>
										</Card>
									</Grid>
								)
							}
						)}
				</Grid>
			</Box>
		</Paper>
	)
}

export default Provider
