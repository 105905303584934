import React, { useEffect } from 'react'
import Step from './step'
import Success from './success'
import Coming from './coming'
import Error from './error'
import { useDispatch } from 'react-redux'
import { setOptions } from '../../store/reducers/order'
import { Switch, Route, Redirect } from 'react-router-dom'

const queryString = require('query-string')

const Order = () => {
	const dispatch = useDispatch()
	const parsed = queryString.parse(window.location.search)

	useEffect(() => {
		dispatch(setOptions(parsed))
	}, [dispatch, parsed])

	return (
		<Switch>
			<Route path="/order/step" component={Step} />
			<Route path="/order/success" component={Success} />
			<Route path="/order/coming" component={Coming} />
			<Route path="/order/error" component={Error} />
			<Redirect from="/order" to="/order/step" />
			<Redirect from="/order/mobile" to="/order/step" />
		</Switch>
	)
}

export default Order
