import { put } from 'redux-saga/effects'
import jwtDecode from 'jwt-decode'
import { Types as TypesAuth } from '../reducers/auth'
export const Types = {
	AUTHENTICATION: 'saga/auth/@AUTHENTICATION',
}
export function* Authentication({ payload }) {
	if (payload && payload !== '') {
		const decoded = jwtDecode(payload)
		if (decoded) {
			localStorage.setItem('token', payload)
			yield put({
				type: TypesAuth.AUTHENTICATION,
				payload: { ...decoded, token: payload },
			})
		} else {
			yield put({
				type: TypesAuth.AUTHENTICATION,
				payload: {
					role: '',
					token: '',
				},
			})
		}
	} else {
		yield put({
			type: TypesAuth.AUTHENTICATION,
			payload: {
				role: '',
				token: '',
			},
		})
	}
}
