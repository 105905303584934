import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import Order from './order'

const Scenes = () => {
	return (
		<Router>
			<Switch>
				<Route path="/order" component={Order} />
				<Redirect from="/" to="order" />
			</Switch>
		</Router>
	)
}

export default Scenes
