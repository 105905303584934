import React from 'react'
import { Provider } from 'use-http'
import { useSelector } from 'react-redux'

const Http = ({ children }) => {
	const { token } = useSelector(({ Auth }) => Auth)
	const options = {
		headers: {
			Accept: 'application/json',
			authorization: `Bearer ${token}`,
		},
	}
	return (
		<Provider url={process.env.REACT_APP_HOST} options={options}>
			{children}
		</Provider>
	)
}

export default Http
