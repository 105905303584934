import { Component } from 'react'
import { setError } from './store/reducers/order'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends Component {
	componentDidMount() {
		window.onunhandledrejection = err => {
			console.log(err.reason)
			if (err.reason) {
				Sentry.captureException(err.reason)
			}
			this.props.dispatch(setError(true))
		}
	}

	componentDidCatch(err, info) {
		// Need next line to prevent: Warning: ErrorBoundary: Error boundaries should implement getDerivedStateFromError(). In that method, return a state update to display an error message or fallback UI.
		this.setState({ hasError: false })
		Sentry.captureException(err)
		this.props.dispatch(setError(true))
	}

	render() {
		return this.props.children
	}
}

function mapStateToProps(state) {
	return {}
}

export default connect(mapStateToProps)(ErrorBoundary)
