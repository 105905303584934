import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import gtag from 'ga-gtag'
import ReactPixel from 'react-facebook-pixel'

Sentry.init({
	dsn: 'https://7a024e364e674c2c94d123572dae0a03@o203940.ingest.sentry.io/6127866',
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
})

const { head } = document
const script = document.createElement('script')
script.id = 'ga-gtag'
script.type = 'text/javascript'
script.async = true
script.src = `https://www.googletagmanager.com/gtag/js?id=G-Z7ZHCKVSNM`
head.insertBefore(script, head.firstChild)
window.dataLayer = window.dataLayer || []

gtag('js', new Date())
gtag('config', 'G-Z7ZHCKVSNM')
gtag('config', 'AW-10817194239')

// Workaround by Meta React team to fix Chromium bug (https://bugs.chromium.org/p/chromium/issues/detail?id=872770)
if (typeof Node === 'function' && Node.prototype) {
	const originalRemoveChild = Node.prototype.removeChild
	Node.prototype.removeChild = function (child) {
		if (child.parentNode !== this) {
			if (console) {
				console.error(
					'Cannot remove a child from a different parent',
					child,
					this
				)
			}
			return child
		}
		return originalRemoveChild.apply(this, arguments)
	}
	const originalInsertBefore = Node.prototype.insertBefore
	Node.prototype.insertBefore = function (newNode, referenceNode) {
		if (referenceNode && referenceNode.parentNode !== this) {
			if (console) {
				console.error(
					'Cannot insert before a reference node from a different parent',
					referenceNode,
					this
				)
			}
			return newNode
		}
		return originalInsertBefore.apply(this, arguments)
	}
}

const advancedMatching = {}
const options = {
	autoConfig: true,
	debug: false,
}
ReactPixel.init('357354572754650', advancedMatching, options)
ReactPixel.pageView()

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

reportWebVitals()
