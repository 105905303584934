import React from 'react'
import { makeStyles } from '@material-ui/core'
import infoStepImage from '../../../../assets/image/infoStep.png'

const useStyles = makeStyles(theme => ({
	media: {
		maxWidth: '100%',
	},
}))

const InfoFeatures1 = () => {
	const classes = useStyles()

	return <img className={classes.media} src={infoStepImage} alt="" />
}

export default InfoFeatures1
