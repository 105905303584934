import { setAPI, setCryptography } from './payment'
import { setPlans } from './plan'
import { setNumbers } from './number'
import { isMobile } from 'react-device-detect'
import gtag from 'ga-gtag'
import ReactPixel from 'react-facebook-pixel'

const Types = {
	EFN: 'reducer/order/@EFN',
	NAVIGATE: 'reducer/order/@NAVIGATE',
	STEPS: 'reducer/order/@STEPS',
	CITY_CLUE: 'reducer/order/@CITY_CLUE',
	CITY: 'reducer/order/@CITY',
	PROVIDER: 'reducer/order/@PROVIDER',
	PLAN_CLUE: 'reducer/order/@PLAN_CLUE',
	PLAN: 'reducer/order/@PLAN',
	NUMBERS_NEW: 'reducer/order/@NUMBERS_NEW',
	NUMBERS_LNP: 'reducer/order/@NUMBERS_LNP',
	SUBSCRIBER: 'reducer/order/@SUBSCRIBER',
	TECH: 'reducer/order/@TECH',
	TERMSOFUSE: 'reducer/order/@TERMSOFUSE',
	PICTURESELF: 'reducer/order/@PICTURESELF',
	PICTUREDOCUMENT: 'reducer/order/@PICTUREDOCUMENT',
	FINISH: 'reducer/order/@FINISH',
	PRELOAD: 'reducer/order/@PRELOAD',
	RC: 'reducer/order/@RC',
	LOADING: 'reducer/order/@LOADING',
	ERROR: 'reducer/order/@ERROR',
	CONTRACT1: 'reducer/order/@CONTRACT1',
	CONTRACT2: 'reducer/order/@CONTRACT2',
	CONTRACT3: 'reducer/order/@CONTRACT3',
	COMPLETE: 'reducer/order/@COMPLETE',
	COUPON: 'reducer/order/@COUPON',
	COUPON_ERROR: 'reducer/order/@COUPON_ERROR',
	COUPON_CLUE: 'reducer/order/@COUPON_CLUE',
}

export const setOptions = options => {
	return dispatch => {
		if (options && options.c && options.cn) {
			dispatch(setCityClue({ id: options.c, name: options.cn }))
		}
		if (options && options.p) {
			dispatch(setPlanClue({ code: options.p }))
		}
		if (options && options.cpn) {
			dispatch(setCouponClue(options.cpn))
		}
		if (options && options.o && options.oc) {
			dispatch(setRC({ rc: options.o, rcChecksum: options.oc }))
			if (options.fn && options.fn === 'pm') {
				dispatch(setEFN('pm'))
			}
		}
	}
}

export const createOrder = options => {
	return async (dispatch, getState) => {
		dispatch(
			setSteps([
				'city',
				'plan',
				'numbers',
				'infoFeatures',
				'subscriber',
				'tech',
				'termsOfUse',
				'picturePrepare',
				'pictureSelf',
				'pictureDocument',
				'orderSummary',
			])
		)

		const url = getState().Order.rc
			? `/orders/${getState().Order.rc}/${getState().Order.rcChecksum}`
			: '/orders'

		dispatch(setLoading(true))
		// Create or load order
		const order = await post(url)
		dispatch(preload(order))
		// Load providers
		const providers = await get('/providers')
		if (isEmpty(providers) || providers.length === 0)
			throw new Error('No providers!')
		dispatch(updateProvider(providers[0]))
		dispatch(setAPI(providers[0]?.payment?.api))
		dispatch(setCryptography(providers[0]?.payment?.cryptography))
		if (getState().Order.efn) {
			dispatch(navigate(11))
		}
		else {
			if (getState().Order.couponClue) {
				await dispatch(applyCoupon(getState().Order.couponClue))
				dispatch(setCouponClue(null))
				dispatch(setCouponError(false))
			}
			dispatch(navigate(1))
		}
		dispatch(setLoading(false))

		ReactPixel.track('InitiateCheckout', {})

		gtag('event', 'begin_checkout', {
			send_to: 'G-Z7ZHCKVSNM',
		})
	}
}

export const loadPlans = () => {
	return async (dispatch, getState) => {
		const provider = getState().Order.provider
		dispatch(setLoading(true))
		const plans = await get(`/providers/${provider}/plans`)
		if (isEmpty(plans) || plans.length === 0) throw new Error('No plans!')
		dispatch(setPlans(plans))
		dispatch(setLoading(false))
	}
}

const isEmpty = obj => {
	return (
		obj &&
		Object.keys(obj).length === 0 &&
		Object.getPrototypeOf(obj) === Object.prototype
	)
}

export const loadNumbers = () => {
	return async (dispatch, getState) => {
		const city = getState().Order.city.id
		const plan = getState().Order.plan.code
		dispatch(setLoading(true))
		const numbers = await get(`/numbers/city/${plan}/${city}`)
		if (!isEmpty(numbers)) dispatch(setNumbers(numbers))
		else dispatch(setNumbers(null))
		dispatch(setLoading(false))
	}
}

export const updateCity = city => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setCity(city))
		dispatch(setNumbers([]))
		dispatch(setNumbersNew(new Map()))
		dispatch(setNumbersLNP(new Map()))
		dispatch(setContract1(null))
		dispatch(setContract2(null))
		dispatch(setContract3(null))
		dispatch(setComplete('numbers', false))
		dispatch(setComplete('termsOfUse', false))
		dispatch(setComplete('city', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, { city })
		dispatch(setComplete('city'))
		dispatch(setLoading(false))
	}
}

export const updateProvider = provider => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setProvider(provider._id))
		dispatch(setLoading(true))
		await updateOrder(orderId, { provider: getState().Order.provider })
		dispatch(setLoading(false))
	}
}

export const updatePlan = plan => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setPlan(plan))
		dispatch(setContract1(null))
		dispatch(setContract2(null))
		dispatch(setContract3(null))
		// Reset numbers if new plan is Trial
		if (plan?.price === 0) {
			const { numbersLNP, numbersNew } = getState().Order
			if (numbersLNP.size > 0 || numbersNew.size > 0) {
				dispatch(setNumbers([]))
				await dispatch(updateNumbersNew(new Map()))
				await dispatch(updateNumbersLNP(new Map()))
			}
		}
		dispatch(setComplete('numbers', true)) // will recalculate
		dispatch(setComplete('termsOfUse', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, { plan: { _id: plan._id } })
		dispatch(setComplete('plan'))
		dispatch(setLoading(false))

		gtag('event', 'select_content', {
			send_to: 'G-Z7ZHCKVSNM',
			content_type: 'product',
			items: [
				{
					id: plan._id,
					name: plan.name,
					price: plan.price,
				},
			],
		})
	}
}

export const updateNumbersNew = numbers => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setNumbersNew(numbers))
		dispatch(setComplete('numbers', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, {
			numbers: Object.values([...numbers]).map(element => element[1]._id),
		})
		dispatch(setComplete('numbers'))
		dispatch(setLoading(false))
	}
}

const compareMapKeys = (map1, map2) => {
	if (map1.size !== map2.size) {
		return false
	}
	for (var [key] of map1) {
		if (map2.get(key) === undefined) {
			return false
		}
	}
	return true
}

export const updateNumbersLNP = numbers => {
	return async (dispatch, getState) => {
		// <Numbers> send us a lot of updates with same value
		if (compareMapKeys(numbers, getState().Order.numbersLNP)) {
			return
		}
		const orderId = getState().Order.id
		dispatch(setNumbersLNP(numbers))
		dispatch(setComplete('numbers', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, {
			numbersLNP: Object.values([...numbers]).map(element => element[1].number),
		})
		dispatch(setComplete('numbers'))
		dispatch(setLoading(false))
	}
}

export const saveSubscriber = () => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setLoading(true))
		await updateOrder(orderId, {
			subscriber: { ...getState().Order.subscriber },
		})
		dispatch(setLoading(false))
		ReactPixel.track('Lead', {})
	}
}

export const saveTech = () => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setLoading(true))
		await updateOrder(orderId, {
			tech: { ...getState().Order.tech },
		})
		dispatch(setLoading(false))
	}
}

export const updatePictureSelf = picture => {
	return async (dispatch, getState) => {
		dispatch(setComplete('pictureSelf', false))
		if (picture === undefined) {
			dispatch(setPictureSelf(undefined))
			return
		}
		const orderId = getState().Order.id
		dispatch(setPictureSelf({ image: picture }))
		dispatch(setLoading(true))
		await updateOrder(orderId, { pictureSelf: picture })
		dispatch(setLoading(false))
		dispatch(setComplete('pictureSelf'))
	}
}

export const updatePictureDocument = picture => {
	return async (dispatch, getState) => {
		dispatch(setComplete('pictureDocument', false))
		if (picture === undefined) {
			dispatch(setPictureDocument(undefined))
			return
		}
		const orderId = getState().Order.id
		dispatch(setPictureDocument({ image: picture }))
		dispatch(setLoading(true))
		await updateOrder(orderId, { pictureDocument: picture })
		dispatch(setLoading(false))
		dispatch(setComplete('pictureDocument'))
	}
}

export const updateContract1 = datetime => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setContract1(datetime))
		dispatch(setComplete('termsOfUse', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, { contract1: datetime })
		dispatch(setLoading(false))
		dispatch(setComplete('termsOfUse'))
	}
}

export const updateContract2 = datetime => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setContract2(datetime))
		dispatch(setComplete('termsOfUse', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, { contract2: datetime })
		dispatch(setLoading(false))
		dispatch(setComplete('termsOfUse'))
	}
}

export const updateContract3 = datetime => {
	return async (dispatch, getState) => {
		const orderId = getState().Order.id
		dispatch(setContract3(datetime))
		dispatch(setComplete('termsOfUse', false))
		dispatch(setLoading(true))
		await updateOrder(orderId, { contract3: datetime })
		dispatch(setLoading(false))
		dispatch(setComplete('termsOfUse'))
	}
}

export const applyCoupon = couponId => {
	return async (dispatch, getState) => {
		dispatch(setLoading(true))
		let coupon = null
		if (couponId && couponId.length > 0) {
			coupon = await get(`/coupons/${couponId.toUpperCase()}`)
			const empty = Object.keys(coupon).length === 0
			if (empty) coupon = null
			dispatch(setCouponError(empty))
		}
		dispatch(setCoupon(coupon))
		const orderId = getState().Order.id
		await updateOrder(orderId, { coupon })
		dispatch(setLoading(false))
	}
}

const updateOrder = async (orderId, body) => {
	await put(`/orders/${orderId}`, body)
}

export const setProvider = id => {
	return {
		type: Types.PROVIDER,
		payload: id,
	}
}

export const navigate = nextStep => {
	return (dispatch, getState) => {
		dispatch({
			type: Types.NAVIGATE,
			payload: nextStep,
		})

		if (nextStep === 'next') {
			gtag('event', 'checkout_progress', {
				send_to: 'G-Z7ZHCKVSNM',
				checkout_step: getState().stepActive,
			})
		}
	}
}

export const createSubscription = data => {
	return async (dispatch, getState) => {
		dispatch(setLoading(true))
		await post('/payment/subscriptions', {
			data,
			plan_id: getState().Order.plan?._id,
			provider_id: getState().Order.provider,
			order_id: getState().Order.id,
			concluded: new Date().toISOString(),
		})
		dispatch(setLoading(false))
		dispatch(setComplete('summary'))

		const trial = getState().Order.plan?.price === 0

		if (trial) {
			ReactPixel.track('StartTrial', {})

			gtag('event', 'start_trial', {
				send_to: 'G-Z7ZHCKVSNM',
				transaction_id: getState().Order.id,
			})
		} else {
			ReactPixel.track('Purchase', {
				value: getState().Order.plan?.price,
				currency: 'BRL',
			})

			gtag('event', 'purchase', {
				send_to: 'G-Z7ZHCKVSNM',
				transaction_id: getState().Order.id,
				value: getState().Order.plan?.price,
				currency: 'BRL',
				items: [
					{
						id: getState().Order.plan?._id,
						name: getState().Order.plan?.name,
						price: getState().Order.plan?.price,
					},
				],
			})

			gtag('event', 'conversion', {
				send_to: 'AW-10817194239/vk9JCJ2MhYcDEP-RhaYo',
				value: getState().Order.plan?.price,
				currency: 'BRL',
				transaction_id: getState().Order.id,
			})
		}
	}
}

const setCity = city => {
	return {
		type: Types.CITY,
		payload: city,
	}
}

export const setCityClue = city => {
	return {
		type: Types.CITY_CLUE,
		payload: city,
	}
}

const setPlan = plan => {
	return {
		type: Types.PLAN,
		payload: plan,
	}
}

export const setPlanClue = plan => {
	return {
		type: Types.PLAN_CLUE,
		payload: plan,
	}
}

const setCouponClue = coupon => {
	return {
		type: Types.COUPON_CLUE,
		payload: coupon,
	}
}

export const setNumbersNew = numbers => {
	return {
		type: Types.NUMBERS_NEW,
		payload: numbers,
	}
}

export const setNumbersLNP = numbers => {
	return {
		type: Types.NUMBERS_LNP,
		payload: numbers,
	}
}

export const setSubscriber = subscriber => {
	return dispatch => {
		dispatch(setComplete('subscriber', false))
		dispatch({
			type: Types.SUBSCRIBER,
			payload: subscriber,
		})
		if (subscriber) {
			dispatch(setComplete('subscriber'))
		}
	}
}

export const setTech = tech => {
	return dispatch => {
		dispatch(setComplete('tech', false))
		dispatch({
			type: Types.TECH,
			payload: tech,
		})
		dispatch(setComplete('tech'))
	}
}

const setContract1 = datetime => {
	return {
		type: Types.CONTRACT1,
		payload: datetime,
	}
}

const setContract2 = datetime => {
	return {
		type: Types.CONTRACT2,
		payload: datetime,
	}
}

const setContract3 = datetime => {
	return {
		type: Types.CONTRACT3,
		payload: datetime,
	}
}

const setPictureSelf = picture => {
	return {
		type: Types.PICTURESELF,
		payload: picture,
	}
}

const setPictureDocument = picture => {
	return {
		type: Types.PICTUREDOCUMENT,
		payload: picture,
	}
}

const setCoupon = coupon => {
	return {
		type: Types.COUPON,
		payload: coupon,
	}
}

export const setCouponError = flag => {
	return {
		type: Types.COUPON_ERROR,
		payload: flag
	}
}

export const finish = orderId => {
	return {
		type: Types.FINISH,
		payload: orderId,
	}
}

const setLoading = loading => {
	return {
		type: Types.LOADING,
		payload: loading,
	}
}

export const setError = reason => {
	return {
		type: Types.ERROR,
		payload: reason,
	}
}

const preload = order => {
	return {
		type: Types.PRELOAD,
		payload: order,
	}
}

const setSteps = steps => {
	return {
		type: Types.STEPS,
		payload: steps,
	}
}

const setRC = data => {
	return {
		type: Types.RC,
		payload: data,
	}
}

const setEFN = data => {
	return {
		type: Types.EFN,
		payload: data,
	}
}

const setComplete = (step, flag = true) => {
	return {
		type: Types.COMPLETE,
		payload: { step, flag },
	}
}

const makeUrl = path => {
	// This is a hack to keep things simple :-)
	return path.startsWith('/payment')
		? `https://assine.faleuniq.com.br/api/${path}`
		: `https://assine.faleuniq.com.br/api/public/${path}`
}

const get = async url => {
	const response = await fetch(makeUrl(url))
	if (!response.ok) {
		// This is a hack to keep things simple :-)
		if (
			(url.startsWith('/numbers') || url.startsWith('/coupons')) &&
			response.status === 404
		) {
			return {}
		}
		throw Error(`GET ${url} returned ${response.status}`)
	}
	const text = await response.text()
	if (text.length > 0) {
		return parseJSON(text)
	} else {
		return {}
	}
}

const put = async (url, body) => {
	const response = await fetch(makeUrl(url), {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(body),
	})
	if (!response.ok) {
		throw Error(`PUT ${url} returned ${response.status}`)
	}
}

const post = async (url, body) => {
	let fetchOptions
	if (body) {
		fetchOptions = {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(body),
		}
	} else {
		fetchOptions = {
			method: 'POST',
		}
	}
	const response = await fetch(makeUrl(url), fetchOptions)
	if (!response.ok) {
		throw Error(`POST ${url} returned ${response.status}`)
	}
	const text = await response.text()
	if (text.length > 0) {
		return parseJSON(text)
	} else {
		return {}
	}
}

// Seems Chrome has a bug with JSON.parse exceptions and onunhandledrejection
// https://bugs.chromium.org/p/chromium/issues/detail?id=1219363&q=unhandledrejection&can=2
const parseJSON = text => {
	try {
		return JSON.parse(text)
	} catch (e) {
		throw new Error(e)
	}
}

const InitialState = {
	list: [],
	steps: [],
	stepActive: 0,
	progress: 0,
	id: '',
	rc: null,
	efn: null,
	rcChecksum: null,
	city: null,
	cityClue: null,
	concluded: '',
	provider: '',
	plan: null,
	planClue: null,
	numbersNew: new Map(),
	numbersLNP: new Map(),
	termsOfUse: false,
	infoFeatures: true,
	subscriber: null,
	tech: null,
	picturePrepare: true,
	pictureSelf: null,
	pictureDocument: null,
	orderSummary: '',
	loading: false,
	loadingCount: 0,
	error: null,
	contract1: null,
	contract2: null,
	contract3: null,
	remoteAddress: null,
	complete: { infoFeatures: true, picturePrepare: true },
	coupon: null,
	couponError: false,
	couponClue: null,
}

const Order = (state = InitialState, action) => {
	switch (action.type) {
		case Types.RC:
			return {
				...state,
				rc: action.payload.rc,
				rcChecksum: action.payload.rcChecksum,
			}
		case Types.PRELOAD: {
			let complete = { ...state.complete }
			let numbersNew = new Map()
			if (!!action.payload.orderRC) {
				action.payload.numbers.map(value => {
					numbersNew.set(value._id, {
						_id: value._id,
						number: value.number,
						qty: value.qty,
					})
					return value
				})
				complete['subscriber'] = !!action.payload.subscriber
				complete['tech'] = !!action.payload.tech
				complete['city'] = !!action.payload.city
			}
			return {
				...state,
				...action.payload,
				id: action.payload._id,
				numbersNew: numbersNew,
				numbersLNP: new Map(),
				complete,
			}
		}
		case Types.FINISH:
			return { ...InitialState, completedOrderId: action.payload }
		case Types.EFN:
			return { ...state, efn: action.payload }
		case Types.NAVIGATE: {
			if (action.payload === 'next') {
				if (state.stepActive < 11) {
					let stepActive = state.stepActive + 1
					if (isMobile && stepActive === 4) {
						stepActive = stepActive + 1
					}
					if (stepActive === 8) { // Skip steps 8, 9 and 10
						stepActive += 3
					}
					const progress = (stepActive * 100) / 11
					return { ...state, stepActive, progress }
				}
			} else if (action.payload === 'previous') {
				if (state.stepActive > 1) {
					let stepActive = state.stepActive - 1
					if (isMobile && stepActive === 4) {
						stepActive = stepActive - 1
					}
					if (stepActive === 10) { // Skip steps 8, 9 and 10
						stepActive -= 3
					}
					const progress = (stepActive * 100) / 11
					return { ...state, stepActive, progress }
				}
			} else if (Number.isInteger(action.payload)) {
				if (action.payload >= 1 && action.payload <= 11) {
					const progress = (action.payload * 100) / 11
					return { ...state, stepActive: action.payload, progress }
				}
			}
			return { ...state }
		}
		case Types.STEPS:
			return { ...state, steps: action.payload }
		case Types.CITY_CLUE:
			return { ...state, cityClue: action.payload }
		case Types.CITY:
			return { ...state, city: action.payload }
		case Types.PROVIDER:
			return { ...state, provider: action.payload }
		case Types.PLAN_CLUE:
			return { ...state, planClue: action.payload }
		case Types.PLAN:
			return { ...state, plan: action.payload }
		case Types.NUMBERS_NEW:
			return { ...state, numbersNew: action.payload }
		case Types.NUMBERS_LNP:
			return { ...state, numbersLNP: action.payload }
		case Types.TERMSOFUSE:
			return { ...state, termsOfUse: action.payload }
		case Types.SUBSCRIBER:
			return { ...state, subscriber: action.payload }
		case Types.TECH:
			return { ...state, tech: action.payload }
		case Types.PICTURESELF:
			return { ...state, pictureSelf: action.payload }
		case Types.PICTUREDOCUMENT:
			return { ...state, pictureDocument: action.payload }
		case Types.LOADING:
			if (action.payload) {
				state.loadingCount++
			} else {
				state.loadingCount--
			}
			return { ...state, loading: state.loadingCount > 0 }
		case Types.ERROR:
			return { ...state, error: action.payload }
		case Types.CONTRACT1: {
			return { ...state, contract1: action.payload }
		}
		case Types.CONTRACT2: {
			return { ...state, contract2: action.payload }
		}
		case Types.CONTRACT3: {
			return { ...state, contract3: action.payload }
		}
		case Types.COUPON: {
			return { ...state, coupon: action.payload }
		}
		case Types.COUPON_ERROR: {
			return { ...state, couponError: action.payload }
		}
		case Types.COUPON_CLUE: {
			return { ...state, couponClue: action.payload}
		}
		case Types.COMPLETE: {
			if (action.payload.flag === false) {
				const complete = { ...state.complete }
				complete[action.payload.step] = false
				return { ...state, complete }
			}
			if (action.payload.step === 'numbers') {
				const complete = { ...state.complete }
				let count = 0
				for (let value of state.numbersNew.values())
					count = count + parseInt(value.qty)
				for (let value of state.numbersLNP.values())
					count = count + parseInt(value.qty)
				complete[action.payload.step] = count === state.plan.dids
				return { ...state, complete }
			}
			if (action.payload.step === 'termsOfUse') {
				const complete = { ...state.complete }
				complete[action.payload.step] = calculateTermsOfUse(state)
				return { ...state, complete }
			}
			const complete = { ...state.complete }
			complete[action.payload.step] = true
			return { ...state, complete }
		}
		default:
			return state
	}
}

const calculateTermsOfUse = state => {
	const trial = state.plan?.price === 0
	const contract1 = state.contract1
	const contract2 = state.contract2
	const contract3 = state.contract3
	return !!(
		(!trial && contract1 && contract2 && contract3) ||
		(trial && contract1 && contract2)
	)
}

export default Order
