import { combineReducers } from 'redux'
import Auth from './auth'
import Order from './order'
import Plan from './plan'
import Provider from './provider'
import Payment from './payment'
import Number from './number'

const appReducer = combineReducers({
	Auth,
	Order,
	Plan,
	Provider,
	Payment,
	Number,
})

export const resetState = () => {
	return {
		type: 'RESET_STATE',
	}
}

const rootReducer = (state, action) => {
	if (action.type === 'RESET_STATE') {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default rootReducer
