const Types = {
	LIST: 'reducer/plan/@LIST',
}

export const setPlans = plans => {
	return {
		type: Types.LIST,
		payload: plans,
	}
}

const InitialState = {
	list: [],
	selected: {},
}

const Plan = (state = InitialState, action) => {
	switch (action.type) {
		case Types.LIST:
			return { ...state, list: action.payload }
		default:
			return state
	}
}

export default Plan
