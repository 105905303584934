import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetState } from '../../../store/reducers'
import { Form } from '@unform/web'
import gtag from 'ga-gtag'
import {
	makeStyles,
	Typography,
	Paper,
	Box,
	Button,
	AppBar,
	Toolbar,
	Grid,
	Container,
} from '@material-ui/core'
import bgImage from '../../../assets/image/bg.jpg'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		position: 'relative',
		backgroundColor: '#F4F4F4',
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	appbar: {
		backgroundColor: '#fff',
	},
	paper: {
		padding: '32px',
		position: 'absolute',
		width: '700px',
		top: '50%',
		right: '50%',
		transform: 'translate(50%,-50%)',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			position: 'static',
			height: 'auto',
			width: 'auto',
			transform: 'translate(0%,0%)',
			marginTop: '32px',
		},
	},
	toolbar: {
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 100,
		paddingLeft: 100,
	},
	media: {
		maxWidth: '100%',
	},
	successTitle: {
		width: '100%',
		fontSize: '34px',
		color: '#626262',
	},
	successRequestTitle: {
		fontSize: '14px',
		fontWeight: '700',
		color: '#626262',
	},
	successIcon: { margin: '20px' },
	successRequestNumber: {
		fontSize: '18px',
		fontWeight: '400',
		color: '#626262',
	},
	successRequestDescription: {
		fontSize: '16px',
		fontWeight: '400',
		color: '#626262',
		marginTop: '25px',
		textAlign: 'justify',
	},
	footer: {
		paddingLeft: '73px',
		paddingBottom: '28px',
		marginTop: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	footerText: {
		color: '#aaa',
		marginRight: '5px',
	},
	form: {
		color: '#626262',
		fontSize: '16px',
		marginTop: '55px',
	},
	button: {
		color: '#a2a2a2',
		border: '1px solid #a2a2a2',
	},
}))

const Error = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		dispatch(resetState())

		gtag('event', 'page_view', {
			send_to: 'G-Z7ZHCKVSNM',
			page_title: 'Error found',
			page_path: '/order/error',
		})
	}, [dispatch])

	const handleFormSubmit = async () => {
		history.push('/')
	}

	return (
		<Grid item xs={12} className={classes.root}>
			<AppBar position="static" color="default" className={classes.appbar}>
				<Toolbar className={classes.toolbar}>
					<img
						src="https://www.faleuniq.com.br/images/logo-01.png"
						alt="logo"
						style={{
							height: 50,
						}}
					/>
				</Toolbar>
			</AppBar>
			<main>
				<Container>
					<Paper className={classes.paper}>
						<Box>
							<Container>
								<Typography variant="h4" gutterBottom>
									Um erro ocorreu !
								</Typography>
								<Typography className={classes.successRequestDescription}>
									Infelizmente um erro inesperado foi identificado e não podemos
									continuar com o processamento do seu pedido. Provavelmente a
									causa esteja relacionada com o acesso à Intenet. Mas não se
									preocupe, você pode iniciar o processo novamente.
								</Typography>
								<Form
									onSubmit={handleFormSubmit}
									index={0}
									className={classes.form}
								>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Button
												onClick={handleFormSubmit}
												className={classes.button}
											>
												Iniciar novo pedido
											</Button>
										</Grid>
									</Grid>
								</Form>
							</Container>
						</Box>
					</Paper>
				</Container>
			</main>
		</Grid>
	)
}

export default Error
