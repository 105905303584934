import React, { useEffect, useState, useCallback, useRef } from 'react'
import * as Yup from 'yup'
import {
	Typography,
	Paper,
	makeStyles,
	Tabs,
	Tab,
	Grid,
	Box,
} from '@material-ui/core'
import { isValidCPF, isValidCNPJ, isValidEmail, getStates } from '@brazilian-utils/brazilian-utils'
import SwipeableViews from 'react-swipeable-views'
import { setSubscriber } from '../../../../store/reducers/order'
import useFetch from 'use-http'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from '@unform/web'
import Input from '../../../../components/input'
import { isMobile } from 'react-device-detect'

const _ = require('lodash')

const warning =
	'Caso o seu interesse no serviço seja para uso particular, apenas lembramos que o produto foi desenvolvido para o mercado empresarial. É importante destacar que o Uniq é para uso através da Internet, seja no seu computador ou celular, e não será instalado um telefone fixo convencional no endereço do cadastro.'

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		overflow: 'hidden',
	},
	paper: {
		padding: 32,
		height: '540px',
		width: '820px',
		borderRadius: '10px',
		border: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
		},
	},
	media: {
		height: 100,
		width: '100%',
	},
	gridContainer: {
		height: '100%',
		paddingBottom: 20,
	},
	card: {
		margin: 10,
	},
	gridRoot: {
		height: '410px',
		marginTop: '25px',
		padding: '10px 12px',
		overflowY: 'auto',
		overflowX: 'hidden',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
		},
	},
	icon: {
		marginBottom: 0,
		marginRight: 7,
	},
	borderedTab: {
		border: '2px solid #0F94DA',
		borderRadius: '5px',
		paddingTop: '0px',
		paddingBottom: '0px',
		'& span': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
			color: '#626262',
		},
	},
	unborderedTab: {
		border: '2px solid #F4F4F4',
		borderRadius: '5px',
		paddingTop: '0px',
		paddingBottom: '0px',
		'& span': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
			color: '#626262',
		},
	},
	appBar: {
		backgroundColor: '#fff',
	},
	form: {
		marginTop: '10px',
		paddingRight: '20px',
		overflow: 'hidden',
		'& input': {
			fontSize: '14px',
			borderBottom: '1.3px solid #626262',
		},
	},
	labelIcon: {
		minHeight: '40px',
	},
	formSubTitle: {
		width: '100%',
		fontSize: '16px',
		color: '#626262',
		fontWeight: 500,
		marginTop: '35px',
		marginBottom: '10px',
		paddingLeft: '12px',
	},
	tabs: {
		backgroundColor: 'transparent',
	},
	warning: {
		backgroundColor: '#FFF4E5',
		padding: '10px',
		fontSize: '15px',
		marginTop: '10px',
	},
}))

const Subscriber = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const formRefPerson = useRef()
	const formRefCompany = useRef()
	const { get, response } = useFetch({})
	const { Order: StoreOrder } = useSelector(({ Order }) => {
		return { Order }
	})
	const [value, setValue] = useState(0)
	const [validCNPJ, setValidCNPJ] = useState(true)
	const [validCPF, setValidCPF] = useState(true)
	const [validCompanyEmail, setValidCompanyEmail] = useState(true)
	const [validPersonEmail, setValidPersonEmail] = useState(true)
	const [validCompanyState, setValidCompanyState] = useState(true)
	const [validPersonState, setValidPersonState] = useState(true)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = index => {
		setValue(index)
	}

	const saveSubscriber = useCallback(
		data => {
			dispatch(setSubscriber({ ...StoreOrder.subscriber, ...data }))
		},
		[dispatch, StoreOrder.subscriber]
	)

	const isValidState = state => {
		return getStates().some(e => {
			return e.code === state.toUpperCase()
		})
	}

	const handleCPFChange = event => {
		const maskedCpf = event.target.value
		const cpf = maskedCpf.replace(/[./\-_]*/g, '')
		if (cpf.length === 0) {
			setValidCPF(true)
		} else if (cpf.length === 11) {
			setValidCPF(isValidCPF(cpf))
		}
	}

	const handleCompanyEmailChange = event => {
		const email = event.target.value
		if (!email || email === '') {
			setValidCompanyEmail(true)
		} else {
			setValidCompanyEmail(isValidEmail(email))
			formRefCompany?.current?.submitForm()
		}
	}

	const handlePersonEmailChange = event => {
		const email = event.target.value
		if (!email || email === '') {
			setValidPersonEmail(true)
		} else {
			setValidPersonEmail(isValidEmail(email))
			formRefPerson?.current?.submitForm()
		}
	}

	const handleCompanyStateChange = event => {
		const state = event.target.value
		if (!state || state === '') {
			setValidCompanyState(true)
		} else {
			setValidCompanyState(isValidState(state))
		}
		formRefCompany?.current?.submitForm()
	}

	const handlePersonStateChange = event => {
		const state = event.target.value
		if (!state || state === '') {
			setValidPersonState(true)
		} else {
			setValidPersonState(isValidState(state))
		}
		formRefPerson?.current?.submitForm()
	}

	async function queryCompany(event) {
		const maskedCpnj = event.target.value
		const cnpj = maskedCpnj.replace(/[./\-_]*/g, '')
		if (cnpj.length === 0) {
			setValidCNPJ(true)
		} else if (cnpj.length === 14) {
			setValidCNPJ(isValidCNPJ(cnpj))
			await get('/public/edata/cnpj/' + cnpj)
			if (response.ok) {
				const ed = response.data
				formRefCompany?.current?.setFieldValue('name', ed.nome)
				formRefCompany?.current?.setFieldValue(
					'cep',
					ed.cep.replace(/[.]*/g, '')
				)
				formRefCompany?.current?.setFieldValue('city', ed.municipio)
				formRefCompany?.current?.setFieldValue('state', ed.uf)
				formRefCompany?.current?.setFieldValue('address', ed.logradouro)
				formRefCompany?.current?.setFieldValue('neighborhood', ed.bairro)
				formRefCompany?.current?.setFieldValue('number', ed.numero)
				formRefCompany?.current?.setFieldValue('complement', ed.complemento)
			}
		}
		formRefCompany?.current?.submitForm()
	}

	async function queryZip(event) {
		const maskedZipcode = event.target.value
		const zipcode = maskedZipcode.replace(/[./\-_]*/g, '')
		if (zipcode.length === 8) {
			await get('/public/edata/zip/' + zipcode)
			if (response.ok && response?.data?.uf !== undefined) {
				if (value === 0) {
					formRefCompany?.current?.setFieldValue(
						'city',
						_.upperCase(response.data.localidade)
					)
					formRefCompany?.current?.setFieldValue(
						'state',
						_.upperCase(response.data.uf)
					)
					formRefCompany?.current?.setFieldValue(
						'neighborhood',
						_.upperCase(response.data.bairro)
					)
					formRefCompany?.current?.setFieldValue(
						'address',
						_.upperCase(response.data.logradouro)
					)
				} else {
					formRefPerson?.current?.setFieldValue(
						'city',
						_.upperCase(response.data.localidade)
					)
					formRefPerson?.current?.setFieldValue(
						'state',
						_.upperCase(response.data.uf)
					)
					formRefPerson?.current?.setFieldValue(
						'neighborhood',
						_.upperCase(response.data.bairro)
					)
					formRefPerson?.current?.setFieldValue(
						'address',
						_.upperCase(response.data.logradouro)
					)
				}
			}
		}
	}

	const handleFormSubmitPerson = async data => {
		try {
			formRefPerson?.current?.setErrors({})
			const schema = Yup.object().shape({
				cpf: Yup.string().required(),
				name: Yup.string().required(),
				email: Yup.string().required().email(),
				phone: Yup.string().required(),
				address: Yup.string().required(),
				number: Yup.string().required(),
				complement: Yup.string(),
				cep: Yup.string().required(),
				city: Yup.string().required(),
				state: Yup.string()
					.required()
					.test('state', 'state', value => {
						return isValidState(value)
					}),
				neighborhood: Yup.string().required(),
			})
			await schema.validate(data, {
				abortEarly: false,
			})
			saveSubscriber(data)
		} catch (err) {
			// const validationErrors = {}
			// if (err instanceof Yup.ValidationError) {
			// 	err.inner.forEach(error => {
			// 		validationErrors[error.path] = error.message
			// 	})
			// 	formRefPerson?.current?.setErrors(validationErrors);
			// }
		}
	}

	const handleFormSubmitCompany = async data => {
		try {
			formRefCompany?.current?.setErrors({})
			const schema = Yup.object().shape({
				cnpj: Yup.string().required(),
				name: Yup.string().required(),
				contactName: Yup.string().required(),
				contactEmail: Yup.string().required().email(),
				contactPhone: Yup.string().required(),
				address: Yup.string().required(),
				number: Yup.string().required(),
				complement: Yup.string(),
				cep: Yup.string().required(),
				city: Yup.string().required(),
				state: Yup.string()
					.required()
					.test('state', 'state', value => {
						return isValidState(value)
					}),
				neighborhood: Yup.string().required(),
			})
			await schema.validate(data, {
				abortEarly: false,
			})
			saveSubscriber(data)
		} catch (err) {
			// const validationErrors = {}
			// if (err instanceof Yup.ValidationError) {
			// 	err.inner.forEach(error => {
			// 		validationErrors[error.path] = error.message
			// 	})
			// 	formRefCompany?.current?.setErrors(validationErrors);
			// }
		}
	}

	useEffect(() => {
		if (StoreOrder?.subscriber) {
			const { cpf, cnpj } = StoreOrder?.subscriber
			if (isValidCNPJ(cnpj)) {
				setValue(0)
			} else if (isValidCPF(cpf)) {
				setValue(1)
			}
		}
	}, [StoreOrder?.subscriber, setValue])

	return (
		<>
			<Paper className={classes.paper}>
				<Typography variant="h4" gutterBottom>
					Dados do contratante
				</Typography>
				<Box className={classes.gridRoot}>
					<Tabs
						classes={{ indicator: classes.tabs }}
						onChange={handleChange}
						value={value}
						textColor="primary"
						variant="fullWidth"
					>
						<Tab
							className={
								value === 0 ? classes.borderedTab : classes.unborderedTab
							}
							classes={{ labelIcon: classes.labelIcon }}
							style={{ marginRight: '10px' }}
							label="Para sua empresa"
						/>
						<Tab
							className={
								value === 1 ? classes.borderedTab : classes.unborderedTab
							}
							classes={{ labelIcon: classes.labelIcon }}
							label="Para você"
						/>
					</Tabs>
					<SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
						<Form
							initialData={{ ...StoreOrder.subscriber }}
							ref={formRefCompany}
							onSubmit={handleFormSubmitCompany}
							value={value}
							index={0}
							className={classes.form}
						>
							<Grid container spacing={3}>
								<h4 className={classes.formSubTitle}>Dados da empresa</h4>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="cnpj"
										placeholder="CNPJ"
										fullWidth
										type="cpnj"
										onChange={queryCompany}
										error={validCNPJ ? false : 'Informe um CNPJ válido'}
									/>
								</Grid>
								<Grid item xs={12}>
									<Input
										required
										name="name"
										placeholder="Razão Social"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>

								<h4 className={classes.formSubTitle}>Endereço</h4>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="cep"
										placeholder="CEP"
										fullWidth
										type="zipcode"
										onChange={queryZip}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="state"
										placeholder="Estado"
										fullWidth
										error={validCompanyState ? false : 'Informe um estado válido'}
										onBlur={handleCompanyStateChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="city"
										placeholder="Cidade"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="neighborhood"
										placeholder="Bairro"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="address"
										placeholder="Rua/Avenida"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										name="number"
										placeholder="Número"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="complement"
										placeholder="Complemento (opcional)"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>

								<h4 className={classes.formSubTitle}>Contato administrativo</h4>
								<Grid item xs={12}>
									<Input
										required
										name="contactName"
										placeholder="Nome"
										fullWidth
										onBlur={() => formRefCompany?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="contactEmail"
										placeholder="E-mail"
										error={
											validCompanyEmail ? false : 'Informe um e-mail válido'
										}
										fullWidth
										onBlur={handleCompanyEmailChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="contactPhone"
										placeholder="Telefone"
										fullWidth
										type="phone"
										onChange={() => formRefCompany?.current?.submitForm()}
										onBlur={() => {
											if (!isMobile) {
												formRefCompany?.current
													?.getFieldRef('cnpj')
													.current.focus()
											}
										}}
									/>
								</Grid>
							</Grid>
						</Form>
						<Form
							initialData={{ ...StoreOrder.subscriber }}
							ref={formRefPerson}
							onSubmit={handleFormSubmitPerson}
							value={value}
							index={1}
							className={classes.form}
						>
							<Paper variant="outlined" className={classes.warning}>{warning}</Paper>
							<Grid container spacing={3}>
								<h4 className={classes.formSubTitle}>Dados pessoais</h4>
								<Grid item xs={12}>
									<Input
										required
										name="name"
										placeholder="Nome completo"
										fullWidth
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="cpf"
										placeholder="CPF"
										fullWidth
										type="cpf"
										error={validCPF ? false : 'Informe um CPF válido'}
										onChange={handleCPFChange}
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="birthdate"
										placeholder="Data de nascimento"
										fullWidth
										type="date"
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>

								<h4 className={classes.formSubTitle}>Contato</h4>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="email"
										placeholder="E-mail"
										error={
											validPersonEmail ? false : 'Informe um e-mail válido'
										}
										fullWidth
										onBlur={handlePersonEmailChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="phone"
										placeholder="Telefone"
										fullWidth
										type="phone"
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>

								<h4 className={classes.formSubTitle}>Endereço</h4>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="cep"
										placeholder="CEP"
										fullWidth
										type="zipcode"
										onChange={queryZip}
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="state"
										placeholder="Estado"
										fullWidth
										error={validPersonState ? false : 'Informe um estado válido'}
										onBlur={handlePersonStateChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="city"
										placeholder="Cidade"
										fullWidth
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="neighborhood"
										placeholder="Bairro"
										fullWidth
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="address"
										placeholder="Rua/Avenida"
										fullWidth
										onBlur={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										name="number"
										placeholder="Número"
										fullWidth
										onChange={() => formRefPerson?.current?.submitForm()}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										required
										name="complement"
										placeholder="Complemento (opcional)"
										fullWidth
										onBlur={() => {
											formRefPerson?.current?.submitForm()
											if (!isMobile) {
												formRefPerson?.current
													?.getFieldRef('name')
													.current.focus()
											}
										}}
									/>
								</Grid>
							</Grid>
						</Form>
					</SwipeableViews>
				</Box>
			</Paper>
		</>
	)
}

export default Subscriber
